import { createSlice } from "@reduxjs/toolkit";
import onboardingActions from "./onboardingActions";

export type AppTheme = {
  name: string;
  id: number;
  type: string;
};

export type stateType = {
  data: any;
  isLoading: boolean;
  error: string;
};

const stateData: stateType = {
  data: null,
  isLoading: false,
  error: "",
};

export type OnboardingState = {
  access_token: string;
  signIn: stateType;
  signUp: stateType;
  userInfoById: stateType;
  userProfile: {};
  userPayment: {};
  onboardingLoading: boolean;
  onboardingError: string;
  connectedSocials: {
    accounts: [];
    connectedSocialsLoading: boolean;
    connectedSocialsError: string;
  };
  appTheme: AppTheme;
};

const initialState: OnboardingState = {
  access_token: "",
  signIn: {
    data: null,
    isLoading: false,
    error: "",
  },
  signUp: {
    data: {},
    isLoading: false,
    error: "",
  },
  userInfoById: stateData,
  userProfile: {},
  userPayment: {},
  onboardingLoading: false,
  onboardingError: "",
  connectedSocials: {
    accounts: [],
    connectedSocialsLoading: false,
    connectedSocialsError: "",
  },
  appTheme: { name: "Viral Dark Theme", id: 1, type: "dark" },
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    clearInputState: (state) => {
      state.access_token = "";
      state.signIn.data = null;
      state.signIn.error = "";
      state.signIn.isLoading = false;
    },
  },
  extraReducers: {
    //email sign in handler
    [onboardingActions.emailSignin.fulfilled.toString()]: (state, action) => {
      state.signIn.isLoading = false;
      state.access_token = action.payload.access_token;
      state.signIn.data = action.payload.data;
      state.signIn.error = "";
    },
    [onboardingActions.emailSignin.pending.toString()]: (state) => {
      state.signIn.isLoading = true;
    },
    [onboardingActions.emailSignin.rejected.toString()]: (state, action) => {
      state.signIn.isLoading = false;
      state.signIn.error = action.error;
    },

    // get user info by id
    [onboardingActions.getUserInfoById.fulfilled.toString()]: (state, action) => {
      state.userInfoById.data = action.data;
      state.userInfoById.isLoading = false;
      state.userInfoById.error = '';
    },
    [onboardingActions.getUserInfoById.pending.toString()]: (state) => {
      state.userInfoById.isLoading = true;
    },
    [onboardingActions.getUserInfoById.rejected.toString()]: (state, action) => {
      state.userInfoById.isLoading = false;
      state.userInfoById.error = action.error.msg;
    },

    // google sign in and sign up handler
    [onboardingActions.googleSignUp.fulfilled.toString()]: (state, action) => {
      state.access_token = action.payload.data.access_token;
      state.signIn.data = action.payload.data;
      state.signIn.isLoading = false;
      state.signIn.error = "";
    },
    [onboardingActions.googleSignUp.pending.toString()]: (state) => {
      state.signIn.isLoading = true;
    },
    [onboardingActions.googleSignUp.rejected.toString()]: (state, action) => {
      state.signUp.isLoading = false;
      state.signUp.error = action.error;
    },

    // facebook sign in and sign up handler
    [onboardingActions.facebookSignUp.fulfilled.toString()]: (state, action) => {
      state.signIn.isLoading = false;
      state.access_token = action.payload.data.access_token;
      state.signIn.data = action.payload.data;
      state.signIn.error = "";
    },
    [onboardingActions.facebookSignUp.pending.toString()]: (state) => {
      state.signIn.isLoading = true;
    },
    [onboardingActions.facebookSignUp.rejected.toString()]: (state, action) => {
      state.signUp.isLoading = false;
      state.signUp.error = action.error;
    },

    // apple sign in and sign up handler
    [onboardingActions.appleSignUp.fulfilled.toString()]: (state, action) => {
      state.signIn.isLoading = false;
      state.access_token = action.payload.data.access_token;
      state.signIn.data = action.payload.data;
      state.signIn.error = "";
    },
    [onboardingActions.appleSignUp.pending.toString()]: (state) => {
      state.signIn.isLoading = true;
    },
    [onboardingActions.appleSignUp.rejected.toString()]: (state, action) => {
      state.signUp.isLoading = false;
      state.signUp.error = action.error;
    },

    //get connected socials handler
    [onboardingActions.getConnectedSocials.fulfilled.toString()]: (state, action) => {
      state.connectedSocials.accounts = action.payload;
      state.connectedSocials.connectedSocialsLoading = false;
      state.connectedSocials.connectedSocialsError = "";
    },
    [onboardingActions.getConnectedSocials.pending.toString()]: (state) => {
      state.connectedSocials.connectedSocialsLoading = true;
      state.connectedSocials.connectedSocialsError = "";
    },
    [onboardingActions.getConnectedSocials.rejected.toString()]: (state, action) => {
      state.connectedSocials.connectedSocialsLoading = false;
      state.connectedSocials.connectedSocialsError = action.error;
    },

    //access token handler
    [onboardingActions.setAccessToken.fulfilled.toString()]: (state, action) => {
      state.access_token = action.payload;
      state.onboardingLoading = false;
      state.onboardingError = "";
    },
    [onboardingActions.setAccessToken.pending.toString()]: (state) => {
      state.onboardingLoading = true;
    },
    [onboardingActions.setAccessToken.rejected.toString()]: (state, action) => {
      state.onboardingLoading = false;
      state.onboardingError = action.error;
    },
    //get user profile handler
    [onboardingActions.getUserProfile.pending.toString()]: (state) => {
      state.onboardingLoading = true;
      state.onboardingError = "";
    },
    [onboardingActions.getUserProfile.fulfilled.toString()]: (state, action) => {
      state.userProfile = action.payload;
      state.onboardingLoading = false;
      state.onboardingError = "";
    },
    [onboardingActions.getUserProfile.rejected.toString()]: (state, action) => {
      state.onboardingLoading = false;
      state.onboardingError = action.error;
    },

    //change theme handler
    [onboardingActions.changeTheme.pending.toString()]: (state) => {
      state.onboardingLoading = true;
      state.onboardingError = "";
    },
    [onboardingActions.changeTheme.fulfilled.toString()]: (state, action) => {
      state.appTheme = action.payload.theme_id === 1 ? { name: "Viral Dark Theme", id: 1, type: "dark" } : { name: "Viral Light Theme", id: 2, type: "Light" };
      state.onboardingLoading = false;
      state.onboardingError = "";
    },
    [onboardingActions.changeTheme.rejected.toString()]: (state, action) => {
      state.onboardingError = action.error;
    },
  },
});

export const { clearInputState } = onboardingSlice.actions;
export default onboardingSlice.reducer;
