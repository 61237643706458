/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { SuperAdminEndPoint, RolesEndpoint } from "../../core/constants/Api";

//API call to get all login data for all users on the platform. Time frame is input which determines fom what time period
//data is taken from. Used to show graph on Admin section platform tab "DailyEngagementActivity"
const getLoginDataAPI = async (access_token: string, start_date: number | string, last_date: number | string, key: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/logindata", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
        filter_key: +key,
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of signups on a country basis. Used to display a comparison in a chart in admin section.
const getSignupByCountryAPI = async (access_token: string, start_date: number | string, last_date: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bycountry", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of signups on a city basis. Used to display a comparison in a chart in admin section.
const getSignupByCityAPI = async (access_token: string, start_date: number | string, last_date: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bycity", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of signups on a gender basis. Used to display a comparison in a chart in admin section.
const getSignupByGenderAPI = async (access_token: string, start_date: number | string, last_date: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bygender", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of signups on a vertical basis. Used to display a comparison in a chart in admin section.
const getSignupByVerticalAPI = async (access_token: string, start_date: number | string, last_date: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/byvertical", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of signups on a lead basis. Used to display a comparison in a chart in admin section.
const getSignupByLeadsAPI = async (access_token: string, start_date: number | string, last_date: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bylead", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of signups on an age group basis. Used to display a comparison in a chart in admin section.
const getSignupByAgeGroupAPI = async (access_token: string, start_date: number | string, last_date: number | string) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/byagegroup", {
      headers: {
        Authorization: access_token,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the number of current active users on the platform. Used to display on the admin section under the core metrics
//section
const getActiveUsersAPI = async (access_token: any) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/users/active", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get the monthly and annual recurring revenue for the platform.
const getRevenueAPI = async (access_token: string, month: number, year: number) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/revenue", {
      headers: {
        Authorization: access_token,
      },
      params: {
        month: month,
        year: year,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

//API call to get a list of the accounts on the platform
const getAllUsersAPI = async (access_token: string, year: number, month: number, week: number, rollbackInfo: any) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/allusers", {
      headers: {
        Authorization: access_token,
      },
      params: {
        week: week,
        month: month,
        year: year,
        rollback_by: rollbackInfo.rollbackType,
        rollback_num: rollbackInfo.rollbackNum,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

//API call to get user roles assigned by admin.
const getUserRoleByIdAPI = async (access_token: string, role_id: number) => {
  try {
    const { data } = await axios.get(RolesEndpoint, {
      headers: {
        Authorization: access_token,
      },
      params: {
        role_id: role_id
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const adminServices = {
  getLoginDataAPI,
  getSignupByCountryAPI,
  getSignupByCityAPI,
  getSignupByGenderAPI,
  getSignupByVerticalAPI,
  getSignupByLeadsAPI,
  getSignupByAgeGroupAPI,
  getUserRoleByIdAPI,
  getActiveUsersAPI,
  getRevenueAPI,
  getAllUsersAPI,
};
