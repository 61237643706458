import * as React from "react"

const AdminIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 16" fillOpacity={0} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0L0 3.63636V9.09091C0 14.1364 3.41333 18.8545 8 20C12.5867 18.8545 16 14.1364 16 9.09091V3.63636L8 0ZM8 3.54545C8.52742 3.54545 9.04299 3.70541 9.48152 4.00508C9.92005 4.30476 10.2618 4.7307 10.4637 5.22905C10.6655 5.72739 10.7183 6.27575 10.6154 6.80479C10.5125 7.33383 10.2586 7.81978 9.88562 8.2012C9.51268 8.58262 9.03752 8.84236 8.52024 8.9476C8.00296 9.05283 7.46678 8.99882 6.97951 8.7924C6.49224 8.58598 6.07577 8.23642 5.78275 7.78792C5.48973 7.33942 5.33333 6.81213 5.33333 6.27273C5.33333 5.54941 5.61428 4.85572 6.11438 4.34425C6.61448 3.83279 7.29276 3.54545 8 3.54545ZM8 10.7273C9.77778 10.7273 13.3333 11.7182 13.3333 13.5273C12.7495 14.4274 11.957 15.1657 11.0264 15.6765C10.0958 16.1873 9.05613 16.4546 8 16.4546C6.94387 16.4546 5.90416 16.1873 4.97357 15.6765C4.04299 15.1657 3.25046 14.4274 2.66667 13.5273C2.66667 11.7182 6.22222 10.7273 8 10.7273Z"
      stroke={props.color}
    />
  </svg>
)

export default AdminIcon
