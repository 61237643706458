import {  Route,Switch } from "react-router-dom";
import WebLayout from "../../sharedComponent/navigation/layoutContainer";
// import HomePage from "../../pages/onboarding/homePage/Home";
import TextGenerator from "../../pages/Page1/TextGenerator";
import ImageGenerator from "../../pages/ImageGenerator/ImageGenerator";
import TextToImgGenerator from "../../pages/TextToImgGenerator/TextToImgGenerator";
import NftcardGenerator from "../../pages/NftCard/NftcardGenerator";
const AllRoutes = () => {
  return (
    <Switch>
      {/* <Route exact path="/" component={HomePage} /> */}
      {/* this weblayout is for displaying top bar and side bar */}
      <WebLayout>
        {/* <PublicRoute exact path="/home" component={HomePage} /> */}
        <Route exact path="/" component={TextGenerator} />

        <Route exact path="/textGen" component={TextGenerator} />
        <Route exact path="/imgGen" component={ImageGenerator} />
        <Route exact path="/txtimg" component={TextToImgGenerator} />
        <Route exact path="/nft" component={NftcardGenerator} />

        {/* parent routes needs to be declared like this and not as a simple component */}
      </WebLayout>
    </Switch>
  );
};

export default AllRoutes;
