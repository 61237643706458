import { ADD_POST, FETCH_POST_MIDDLEWARE } from "./actionsMethods/actions";

const PostReducer = (state: any = { loading: true, post: [] }, action: any = {}) => {
  switch (action.type) {
    case ADD_POST:
      return { loading: false, post: [...state.post, action.payload] };
    case FETCH_POST_MIDDLEWARE:
      return { loading: false, post: action.payload };
    default:
      return state;
  }
};

export default PostReducer;
