import { createTheme } from "@mui/material";

// Main Theme
const Dark = createTheme({
  palette: {
    background: {
      default: "#121212",
    },
    primary: {
      main: "#3DACFF",
      light: "#3DB9FF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    nav: {
      dark: "#181818",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
    text: {
      pri: "#FFFFFF",
      sec: "#B3FFFFFF",
      ter: "#80FFFFFF",
    },
    card: {
      main: "#181A1C",
    },
    action: {
      hover: "#1F4076B8",
      disabled: "#4DFFFFFF",
    },
    divider: "#1FFFFFFF",
    data: {
      pri: "#3DACFF",
      sec: "#53DBF9",
      ter: "#21898F",
      pos: "#3FFCAE",
      neg: "#E43B3B",
    },
    alert: {
      red: "#CC1016",
    },
    badge: {
      main: "linear-gradient(180deg, #3DACFF 0%, #3DE8FF 100%);",
    },
    instagram: {
      main: "linear-gradient(23.2deg, #FF45CB -1.18%, #FFA132 87.82%);",
    },
    youtube: {
      main: "linear-gradient(180deg, #D70000 0%, #FF3131 100%);",
    },
    facebook: {
      main: "linear-gradient(180deg, #4467AF 0%, #6697FF 100%);",
    },
    twitter: {
      main: "linear-gradient(180deg, #0072C8 0%, #3DACFF 100%);",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          color: "white",
          paddingLeft: "10px",
          padding: "5px 10px 5px 10px",
          border: "1px solid #424242 !important",
          "&.Mui-focused": {
            border: "1px solid #3075cb !important",
            borderRadius: "5px",
          },
        },
        input: {
          fontsize: "16px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212 !important",
          color: "white",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },

    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          width: "100%",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          "&.MuiAppBar-colorDefault": {
            backgroundColor: "#121212",
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.7)",
          p: {
            marginBottom: 0,
          },
        },
      },
    },

    MuiButton: {
      variants: [
        {
          props: { color: "primary", variant: "contained" },
          style: {
            backgroundColor: `{Dark.palette.primary.main}`,
            color: "white",
            letterSpacing: "1.5px",
            fontWeight: 700,
            padding: "8px 16px",
            "&.Mui-hover ": {
              backgroundColor: `{Dark.palette.primary.light}`,
            },
            "&.Mui-clicked ": {
              opacity: 0.7,
              color: "white",
            },
            "&.Mui-disabled ": {
              opacity: 0.6,
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            border: `{1px solid {Dark.palette.divider}}`,
            fontWeight: 700,
            padding: "8px 16px",
            "&.Mui-hover ": {
              backgroundColor: `{Dark.palette.primary.main}`,
            },
            "&.Mui-clicked ": {
              opacity: 0.7,
              color: "white",
            },
          },
        },
        {
          props: { color: "primary", variant: "text" },
          style: {
            color: `{Dark.palette.primary.main}`,
            fontWeight: 700,
            textTransform: "none",
            padding: "8px 16px",
            "&.Mui-hover ": {
              textDecoration: `underline {Dark.palette.primary.light}`,
            },
            "&.Mui-clicked ": {
              opacity: 0.4,
              color: "white",
            },
          },
        },
      ],
    },
  },
});

export default Dark;
