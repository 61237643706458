import axios from "axios";

import { toast } from "react-toastify";
import {
  uploadVideoEndpoint,
  createSeriesEndpoint,
  GenreEndPoint,
  GetVideosEndPoint,
  GetTagsEndPoint,
  GetSeriesEndPoint,
  GetSeriesOverviewEndPoint,
  GetSectionDetailsEndPoint,
  GetAllSeriesEndPoint,
  GetParticularPlaylistEndPoint,
  LikeSeriesEndpoint,
  GetPlaylistEndPoint,
  AddSeriesToPlaylistEndPoint,
  AddNewPlaylistEndPoint,
  GetChallengeModuleEndPoint,
  GetChallengeTypesModuleEndPoint,
  GetVideoEndPoint,
  GetAllVideosEndPoint,
  AddVideoToPlaylistEndPoint,
  VideoDetailsEndpoint,
  VideoReviewsEndpoint,
  ReplyOverReviewsEndpoint,
  LikeReviewEndpoint,
  FeatureReviewEndpoint,
  SeriesDetailsEndpoint,
  LikeReplyEndpoint,
  updateSeriesEndpoint,
  SeriesYouLikeEndpoint,
  VideoLikeEndpoint,
  SeriesEndpoint,
  WatchHistoryEndpoint,
  updatePublishStatusEndPoint,
  LikedVideosEndpoint,
  LikedCreatorsEndpoint,
  VideosEndpoint,
  createNewSeriesEndpoint,
  GetSeriesDetailsV1,
  DeletePlaylist,
  ReorderPlaylistEndpoint,
  DeleteFromPlaylistEndpoint,
  GetInstructorVideosEndpoint,
  GetInstructorSeriesEndpoint,
  GetChallengesEndpoint,
  GetBadgesEndpoint,
  InstructorLikeEndpoint,
  LikedInstructorLikeEndpoint,
  GetUserBadgesEndpoint,
  SeriesHistoryEndpoint,
  GetUserSocialsEndpoint,
  SubmitAnswerEndpoint,
  GetSeriesReviewsEndpoint,
  MediaUploadEndpoint,
  MediaStreamEndpoint,
  UsersSeriesEndpoint,
} from "../../constants/Api";

axios.defaults.headers.common["Authorization"] = `access_token`;

const uploadVideoAPI = async (data: any) => {
  let obj;
  let { body, token } = data;

  try {
    const { data } = await axios.post(uploadVideoEndpoint, body, {
      headers: { Authorization: `${token}` },
    });
    obj = { data, success: true };
    console.log(obj);
    return obj;
  } catch (error: any) {
    obj = { error, success: false };
    return obj;
  }
};

const createSeriesAPI = async (data: any) => {
  let obj;
  let { body, token } = data;
  try {
    const { data } = await axios.post(createSeriesEndpoint, body, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    console.log(obj);
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

// New Version Create Series
const createNewSeriesAPI = async (data: any) => {
  let obj;
  let { body, token } = data;
  try {
    const { data } = await axios.post(createNewSeriesEndpoint, body, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

// New Version Create Series
const updateNewSeriesAPI = async (data: any) => {
  let obj;
  let { body, token, series_id } = data;
  try {
    const { data } = await axios.put(createNewSeriesEndpoint + "/" + series_id, body, {
      headers: { Authorization: `${token}` },
      // params: { series_id: `${series_id}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const updateSeriesAPI = async (data: any) => {
  let obj;
  let { body, token, series_id } = data;
  try {
    const { data } = await axios.put(updateSeriesEndpoint, body, {
      headers: { Authorization: `${token}` },
      params: { series_id: `${series_id}` },
    });
    obj = data;
    console.log(obj);
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GenreApi = async (token: any) => {
  let obj;
  try {
    const { data } = await axios.get(GenreEndPoint, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    if (error.response) toast.error(error.msg, { theme: "dark" });
  }
};

const GetVideosApi = async (data: any) => {
  let obj;

  const { body, token, params } = data;
  try {
    const { data } = await axios.post(GetVideosEndPoint + `/?user_id=${params.user_id} `, body, {
      headers: { Authorization: `${token}` },
    });

    obj = data.data;
    return obj;
  } catch (error: any) {
    throw error;
  }
};

const GetAllVideosApi = async (data: any) => {
  let obj;

  const { body, token } = data;
  try {
    const { data } = await axios.post(GetAllVideosEndPoint, body, {
      headers: { Authorization: `${token}` },
    });

    obj = data.data;
    return obj;
  } catch (error: any) {
    toast.error("Error Occured! Videos Data", { theme: "dark" });
  }
};
const GetChallengeModules = async () => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetChallengeModuleEndPoint}`, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetChallengesApis = async (userId: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  let id = userId;
  try {
    const { data } = await axios.get(`${GetChallengesEndpoint}/${id}`, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const AddUserChallengeListForSeries = async (series_id: any) => {
  let obj;
  let req = {
    series_id: series_id,
  };
  const token = localStorage.getItem("access_token");
  const user_id = localStorage.getItem("user_id");
  try {
    const { data } = await axios.post(`${GetChallengesEndpoint}/${user_id}`, req, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const AddUserBadgesForSeries = async (badge_id: any) => {
  let obj;
  let req = {
    badge_id: badge_id,
  };
  const token = localStorage.getItem("access_token");
  const user_id = localStorage.getItem("user_id");
  try {
    const { data } = await axios.post(`${GetUserBadgesEndpoint}${user_id}`, req, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetUserSocials = async () => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetUserSocialsEndpoint}`, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetBadgesApis = async (userId: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  let id = userId;
  try {
    const { data } = await axios.get(`${GetBadgesEndpoint}/${id}`, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetChallengeTypes = async (module_id: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetChallengeTypesModuleEndPoint}/?module_id=${module_id}`, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetSeriesApi = async (data: any) => {
  let obj;

  const { body, token, params } = data;
  try {
    const { data } = await axios.post(GetSeriesEndPoint + `/?user_id=${params.user_id}`, body, { headers: { Authorization: `${token}` } });
    obj = data.data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};
const GetTagsApi = async (data: any) => {
  let obj;

  const { token } = data;
  try {
    const { data } = await axios.get(GetTagsEndPoint, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetSeriesOverview = async (seriesId: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetSeriesOverviewEndPoint}/?series_id=${seriesId}`, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetSectionDetails = async (seriesId: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetSectionDetailsEndPoint}/?series_id=${seriesId}`, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetAllSeries = async () => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetAllSeriesEndPoint}`, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    console.log({ error });
    toast.error("Error Occured! Series Data", { theme: "dark" });
  }
};

const updatePublishStatusSeies = async (data: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  let { series_id, toStatus } = data;
  try {
    let seriesInt = parseInt(`${toStatus}`);
    console.log("SERIRES INT ", seriesInt, typeof seriesInt);
    const { data } = await axios.put(
      `${updatePublishStatusEndPoint}`,
      {
        published_status_id: seriesInt,
      },

      {
        headers: { Authorization: `${token}` },
        params: { series_id: `${series_id}` },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    console.log({ error });
    toast.error("Series Publish status update failed", { theme: "dark" });
  }
};

const GetPlayListDetails = async (id, page) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetParticularPlaylistEndPoint}/?playlist_id=${id}&page_no=${page}`, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    let errorMsg = error;
    if (error.response && error.response.data) {
      errorMsg = error.response.data;
    }
    toast.error(errorMsg.msg, { theme: "dark" });
  }
};

const PostLikeSeries = async (data: any) => {
  let obj;
  let { series_id } = data;
  let req = {
    like: data.like,
  };
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.post(`${LikeSeriesEndpoint}/?series_id=${series_id}`, req, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetPlaylists = async (userId: any) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${GetPlaylistEndPoint}/?user_id=${userId}`, {
      headers: { Authorization: `${token}` },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const AddSeriesToPlaylist = async (seriesId, playlistId) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.post(
      `${AddSeriesToPlaylistEndPoint}/?series_id=${seriesId}`,
      { playlist_id: playlistId },
      { headers: { Authorization: `${token}` } }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const AddNewPlaylist = async (user_id, playlist_title) => {
  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.post(`${AddNewPlaylistEndPoint}/?user_id=${user_id}`, { playlist_title }, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const getVideo = async (accessToken, videoId) => {
  let obj;
  try {
    const { data } = await axios.get(`${GetVideoEndPoint}`, {
      headers: {
        Authorization: accessToken,
      },
      params: {
        video_id: videoId,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const getUserPlaylists = async (accessToken, userID) => {
  let obj;
  try {
    const { data } = await axios.get(`${GetPlaylistEndPoint}`, {
      headers: { Authorization: accessToken },
      params: {
        user_id: userID,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const getUsersSeries = async (accessToken) => {
  let obj;
  try {
    const { data } = await axios.get(`${UsersSeriesEndpoint}`, {
      headers: { Authorization: accessToken },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const addVideoToPlaylist = async (accessToken, playlistID, videoID) => {
  let obj;
  try {
    const { data } = await axios.post(
      `${AddVideoToPlaylistEndPoint}`,
      { playlist_id: playlistID },
      {
        headers: { Authorization: accessToken },
        params: {
          video_id: videoID,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetVideoDetails = async (token, videoID) => {
  let obj;
  try {
    const { data } = await axios.get(`${VideoDetailsEndpoint}`, {
      headers: {
        Authorization: token,
      },
      params: {
        video_id: videoID,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PutVideoApprovalState = async (token, videoID, approvalState) => {
  let obj;
  try {
    let body = {
      approval_state_id: approvalState,
    };
    const { data } = await axios.put(GetVideoEndPoint + "s/" + videoID + "/approval-states", body, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PutSeriesApprovalState = async (token, seriesID, approvalState) => {
  let obj;
  try {
    let body = {
      approval_state_id: approvalState,
    };
    const { data } = await axios.put(createSeriesEndpoint + "/" + seriesID + "/approval-states", body, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PutVideoPublishState = async (token, videoID, publishStatus) => {
  let obj;
  try {
    let body = {
      publish_status_id: publishStatus,
    };
    const { data } = await axios.put(VideosEndpoint + "/" + videoID + "/publish-status", body, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PutSeriesPublishState = async (token, seriesID, publishStatus) => {
  let obj;
  try {
    let body = {
      publish_status_id: publishStatus,
    };
    const { data } = await axios.put(SeriesEndpoint + "/" + seriesID + "/publish-status", body, {
      headers: {
        Authorization: token,
      },
      // /academy/series/3/approval-states
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const UpdateVideoDetails = async (request) => {
  let { token, body, video_id } = request;
  let obj;
  try {
    const { data } = await axios.put(`${GetVideoEndPoint}`, body, {
      headers: { Authorization: token },
      params: {
        video_id: video_id,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetVideoReviews = async (videoID, orderBy) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${VideoReviewsEndpoint}?video_id=${videoID}&orderBy=${orderBy}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const EditVideoReview = async (reviewID, reviewString, stars) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  let body = {
    review_string: reviewString,
    stars: stars,
  };
  try {
    const { data } = await axios.patch(`${VideoReviewsEndpoint}/${reviewID}`, body, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PostVideoReviews = async (videoID, reviewString, stars = 0) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    let body = {
      review_string: reviewString,
      stars: stars,
    };
    const { data } = await axios.post(`${VideoReviewsEndpoint}?video_id=${videoID}`, body, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetReplyOverReview = async (review_id) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${ReplyOverReviewsEndpoint}`, {
      headers: {
        Authorization: token,
      },
      params: {
        review_id,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const LikeReview = async (review_id, is_liked = true) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.put(
      `${LikeReviewEndpoint}`,
      {
        like: is_liked,
      },
      {
        headers: {
          Authorization: token,
        },
        params: {
          review_id,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const LikeReply = async (reply_id, is_liked = true) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.put(
      `${LikeReplyEndpoint}`,
      {
        like: is_liked,
      },
      {
        headers: {
          Authorization: token,
        },
        params: {
          reply_id,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PostReplyToReview = async (replyString, reviewID) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    let body = {
      reply_string: replyString,
    };
    const { data } = await axios.post(`${ReplyOverReviewsEndpoint}?user_review_id=${reviewID}`, body, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const FeatureReview = async (review_id, is_featured) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const data = await axios.put(
      `${FeatureReviewEndpoint}`,
      {
        is_featured: is_featured,
      },
      {
        headers: {
          Authorization: token,
        },
        params: {
          review_id,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const getSeriesDetails = async (token, seriesID) => {
  let obj;
  try {
    const { data } = await axios.get(`${SeriesDetailsEndpoint}`, {
      headers: {
        Authorization: token,
      },
      params: {
        series_id: seriesID,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const getSeriesDetailsV1 = async (token, seriesID) => {
  let obj;
  try {
    const { data } = await axios.get(`${GetSeriesDetailsV1}/${seriesID}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetSeriesYouLike = async () => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${SeriesYouLikeEndpoint}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetSeriesReviews = async (seriesID) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${GetSeriesReviewsEndpoint}/${seriesID}/reviews`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PostLikeVideo = async (video_id, like) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.put(
      `${VideoLikeEndpoint}`,
      {
        like,
      },
      {
        headers: {
          Authorization: token,
        },
        params: {
          video_id: video_id,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PostLikeInstructor = async (userid, like) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${InstructorLikeEndpoint}` + userid + "/likes",
      {
        like,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetLikedInstructors = async (userid) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${LikedInstructorLikeEndpoint}` + userid, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetUserBadges = async (userid: any) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${GetUserBadgesEndpoint}` + userid, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const AddSeriesHistory = async (
  series_id: any,
  total_videos: any,
  completed_videos: any,
  total_questions: any,
  completed_questions: any,
  total_challenges: any,
  completed_challenges: any
) => {
  let watch_status_id = 1;
  if (total_videos === completed_videos && total_challenges === completed_challenges && total_questions === completed_questions) {
    watch_status_id = 2;
  }
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${SeriesHistoryEndpoint}`,
      {
        series_id: parseInt(series_id),
        total_videos: total_videos,
        completed_videos: completed_videos,
        total_questions: total_questions,
        total_challenges: total_challenges,
        watch_status_id: watch_status_id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};
const GetSeriesHistory = async () => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${SeriesHistoryEndpoint}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetParticularSeriesHistory = async (series_id: any) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${SeriesHistoryEndpoint}/${series_id}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const AddUserBadges = async (userid: any, badge_id: any) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${GetUserBadgesEndpoint}` + userid,
      {
        badge_id: badge_id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const SubmitSeriesAnswer = async (section_question_id: any, selected_answer: any) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${SubmitAnswerEndpoint}`,
      {
        section_question_id: section_question_id,
        selected_answer: selected_answer,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetWatchHistory = async () => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${WatchHistoryEndpoint}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetVideoWatchHistory = async (videoId) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${WatchHistoryEndpoint}/${videoId}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const DeleteVideoWatchHistory = async (videoId) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.delete(`${WatchHistoryEndpoint}/${videoId}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const PostWatchHistory = async (video_id, duration_spent, watch_status_id) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${WatchHistoryEndpoint}`,
      {
        video_id: video_id,
        duration_spent: duration_spent,
        watch_status_id: watch_status_id,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetLikedVideos = async () => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${LikedVideosEndpoint}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetLikedCreators = async () => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.get(`${LikedCreatorsEndpoint}`, {
      headers: {
        Authorization: token,
      },
    });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetRecommendedVideos = async () => {
  const VideoName = localStorage.getItem("Recent_video_title");
  let obj;
  try {
    const { data } = await axios.get("https://ai.dev.vnplatform.com/movies/content", {
      params: {
        video: VideoName,
        limit: 10,
      },
    });
    obj = data;

    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

// const DeletePlaylistAPI = async (data: any) => {
//   let { playlistID, token } = data
//   let obj
//   try {
//     const { data } = await axios.post(`${DeletePlaylist + "?playlist_id=" + playlistID}`, {
//       headers: {
//         Authorization: token,
//       },
//     })
//     obj = data
//     return obj
//   } catch (error: any) {
//     toast.error(error.response.data.msg, { theme: "dark" })
//   }
// }

const DeletePlaylistAPI = async (data: any) => {
  let { playlistID } = data;

  let obj;
  const token = localStorage.getItem("access_token");
  try {
    const { data } = await axios.post(`${DeletePlaylist}/?playlist_id=${playlistID}`, { playlist_id: playlistID }, { headers: { Authorization: `${token}` } });
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};
const DeleteVideoFromPlaylist = async (playlist_id, video_id) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${DeleteFromPlaylistEndpoint}`,
      {
        playlist_id: playlist_id,
      },
      {
        headers: {
          Authorization: token,
        },
        params: {
          video_id,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const ReorderPlaylist = async (playlist_id, video_order) => {
  const token: any = localStorage.getItem("access_token");
  let obj;
  try {
    const { data } = await axios.post(
      `${ReorderPlaylistEndpoint}`,
      {
        playlist_id: playlist_id,
        video_order: video_order,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetPopularVideosByInstructor = async (instructorId: any, orderValue: any, token: any) => {
  let obj;
  try {
    let { data } = await axios.get(`${GetInstructorVideosEndpoint}/${instructorId}`, {
      params: {
        order: orderValue,
      },
      headers: {
        Authorization: token,
      },
    });

    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

const GetPopularSeriesByInstructor = async (instructorId: any, orderValue: any, token: any) => {
  let obj;
  try {
    let { data } = await axios.get(`${GetInstructorSeriesEndpoint}/${instructorId}`, {
      params: {
        order: orderValue,
      },
      headers: {
        Authorization: token,
      },
    });

    obj = data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

// Media Upload service for uploading file in to s3 and save into dynamoDB
const MediaUploadService = async (formdata) => {
  let obj;
  const token: any = localStorage.getItem("access_token");
  try {
    const { data } = await axios.post(`${MediaUploadEndpoint}/upload-media`, formdata, { headers: { Authorization: token } });
    obj = data;
    return obj;
  } catch (error: any) {
    throw error.response.data;
  }
};

// Media Get from S# bucket
const getVideoUrls = async () => {
  let obj;
  const token: any = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${MediaStreamEndpoint}media-list`, {
      headers: {
        Authorization: token,
      },
    });
    obj = await data;
    return obj;
  } catch (error: any) {
    toast.error(error.msg, { theme: "dark" });
  }
};

export {
  GenreApi,
  uploadVideoAPI,
  createSeriesAPI,
  createNewSeriesAPI,
  GetVideosApi,
  GetAllVideosApi,
  GetTagsApi,
  GetSeriesApi,
  GetSeriesOverview,
  GetSectionDetails,
  GetAllSeries,
  GetPlayListDetails,
  PostLikeSeries,
  GetPlaylists,
  AddSeriesToPlaylist,
  AddNewPlaylist,
  GetChallengeModules,
  GetChallengeTypes,
  getVideo,
  getUserPlaylists,
  addVideoToPlaylist,
  GetVideoDetails,
  UpdateVideoDetails,
  GetVideoReviews,
  GetReplyOverReview,
  PostVideoReviews,
  LikeReview,
  FeatureReview,
  getSeriesDetails,
  PutVideoApprovalState,
  PutSeriesApprovalState,
  PutVideoPublishState,
  PutSeriesPublishState,
  PostReplyToReview,
  LikeReply,
  updateSeriesAPI,
  updateNewSeriesAPI,
  GetSeriesYouLike,
  PostLikeVideo,
  PostLikeInstructor,
  GetLikedInstructors,
  GetWatchHistory,
  PostWatchHistory,
  GetVideoWatchHistory,
  updatePublishStatusSeies,
  DeleteVideoWatchHistory,
  GetLikedVideos,
  GetLikedCreators,
  EditVideoReview,
  GetRecommendedVideos,
  getSeriesDetailsV1,
  DeletePlaylistAPI,
  DeleteVideoFromPlaylist,
  ReorderPlaylist,
  GetPopularVideosByInstructor,
  GetPopularSeriesByInstructor,
  GetChallengesApis,
  GetBadgesApis,
  GetUserBadges,
  AddUserBadges,
  AddSeriesHistory,
  GetSeriesHistory,
  GetParticularSeriesHistory,
  GetUserSocials,
  SubmitSeriesAnswer,
  GetSeriesReviews,
  MediaUploadService,
  AddUserChallengeListForSeries,
  AddUserBadgesForSeries,
  getVideoUrls,
  getUsersSeries,
};
