import React from "react";

import { Grid,Button,Box } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



const ImageGenerator = () => {
    const profileUploadInput = React.useRef(null);

    const [age, setAge] = React.useState('');
    const [checked, setChecked] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen = () => {
        console.log("hello")
      setOpen(true);
    };
    const handleClickOpen2 = () => {
        setOpen2(true);
      };
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
      };
  const handleProfilePicture=(event)=>{
console.log("event",event.target.value)
  }

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
      };
      const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);}
    
  return (
   <Grid  container alignItems="center" justifyContent="center"  direction="column" > 
<Grid item  alignItems={"center"}xs={12} >
    <Box  mt={2} style={{border:'2px solid grey',width:"900px",height:"300px",display:"flex",alignItems:"center",justifyContent:"center"}}>
    <Button variant="contained" onClick={handleClickOpen}>1. Choose base image </Button>
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
        <input
                          name="profile_image"
                          type="file"
                          id="upload_profile_img"
                        //   style={{ display: "none" }}
                          ref={profileUploadInput}
                          onChange={(event) => {
                            handleProfilePicture(event);
                          }}
                        />
        
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{width:"500px"}}>

            </Box>
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
<Box>Pics</Box>
    </Box>
{/* <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
            /> */}


</Grid>

<Grid item mt={2} xs={12}>
<Grid item  alignItems={"center"}xs={12} >
    <Box  mt={2} style={{border:'2px solid grey',width:"900px",height:"300px",display:"flex",alignItems:"center",justifyContent:"center"}}>
    <Button variant="contained" onClick={handleClickOpen2}>2. Choose style image </Button>
    <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><Button variant="contained">Upload Files</Button></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{width:"500px"}}>

            </Box>
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
<Box>Pics</Box>
    </Box>
{/* <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
            /> */}


</Grid></Grid>
<Grid item mt={2} xs={12}>
    <Accordion>
        <AccordionSummary     expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
                        <Typography>Settings</Typography>


        </AccordionSummary>
        <AccordionDetails   sx={{width:"600px"}}>
            <Box >
        <FormControl fullWidth >
            {/* <span>Resolution</span>
<input type="checkbox"/> */}

            <span>Ai Enhance</span>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Weight"
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value={"Auto"}>Auto</MenuItem>
          <MenuItem value={"none"}>None</MenuItem>
          <MenuItem value={"extra smooth"}>Extra Smooth</MenuItem>
          

        </Select>
        <span>Iterations Boost</span>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Weight"
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value={0.6}>x0.6</MenuItem>
          <MenuItem value={1.6}>x1.0</MenuItem>
          <MenuItem value={1.2}>x1.2</MenuItem>
          <MenuItem value={1.5}>x1.5</MenuItem>
          <MenuItem value={2.0}>x2.0</MenuItem>

          

        </Select>
        <span> Style Weight</span>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Weight"
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value={10}>10%</MenuItem>
          <MenuItem value={20}>20%</MenuItem>
          <MenuItem value={30}>30%</MenuItem>
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>

        </Select>
        <span> Style scale</span>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Weight"
          onChange={handleChange}
          variant="standard"
        >
          <MenuItem value={40}>40%</MenuItem>
          <MenuItem value={60}>60%</MenuItem>
          <MenuItem value={80}>80%</MenuItem>
          <MenuItem value={100}>100%</MenuItem>
          <MenuItem value={120}>120%</MenuItem>

        </Select>
        <span>Preserve Colors</span>
        <Switch
      checked={checked}
      onChange={handleCheck}

      />

      </FormControl>
      </Box>
        </AccordionDetails>
    </Accordion>
</Grid>

<Grid item mt={5} xs={12}>
    <Button variant="contained">Generate </Button>
</Grid>
   </Grid>
  );
};
export default ImageGenerator;
