import { Grid, Paper, Typography } from "@mui/material";

export const SideBarMenuItem = ({ id, Icon, text, handleClick, isActive }) => {
  return (
    <Grid item xs={4} onClick={() => handleClick(id)}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        padding="4px"
        sx={{ cursor: "pointer", "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" } }}
      >
        <Paper variant="outlined" sx={{ padding: "4px", borderColor: `${isActive ? "rgb(255, 255, 255)" : "rgba(255, 255, 255, 0.1)"}` }}>
          {Icon}
        </Paper>
        <Typography
          sx={{
            color: `${isActive ? "rgb(255, 255, 255)" : "rgba(255, 255, 255, 0.5)"}`,
            letterSpacing: "0.4px",
            fontSize: "12px",
            paddingTop: "5px",
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};
