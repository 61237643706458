import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Input, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// import { toast } from "react-toastify";

// const REACT_APP_TEXT_GENERATE = process.env.REACT_APP_TEXT_GENERATE;
const TextGenerator = () => {
  const [length, setlength] = useState("");
  const [loading, setloading] = useState<boolean>(false);
  const [text, settext] = useState("");
  const [sample, setsample] = useState("");
  const [result, setresult] = useState<any>([]);

  const textform = {
    text_prompt: text,
    nsample: sample,
    text_length: length,
  };
  const generateText = async () => {
    try {
      // if (process.env.REACT_APP_TEXT_GENERATE) {
      setloading(true);

      // const data= await axios.post("https://testing.vnplatform.net/api/v1/generate/text/",textform)
      const { data } = await axios.post(
        // `${process.env.REACT_APP_TEXT_GENERATE}/generate_text/twitter`,
        "https://api.ai.dev.vnplatform.com/generate_text/twitter",
        textform
      );

      if (data?.generatedText) {
        if (Array.isArray(data.generatedText)) setresult(data.generatedText);
        else {
          setresult([data.generatedText]);
        }
      }
      setloading(false);
      // } else {
      //   toast.error("Something went wrong!", { theme: "dark" });
      // }
    } catch (error) {
      setloading(false);
    }
  };
  // useEffect(()=>{
  //   console.log("result state",result)
  // },[])
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h1">Ai Text Generator</Typography>

      <Input
        disableUnderline={true}
        placeholder="Write a text for generate something"
        sx={{
          marginY: "1rem",
          width: "50%",
          fontSize: "1.5rem",
          border: "1px solid grey",
          borderRadius: "0.25rem",
          paddingX: "1rem",
          paddingY: "0.5rem",
        }}
        value={text}
        onChange={(e) => settext(e.target.value)}
      />
      <Box mt={2}>
        <label>Length</label>
        <Input
          disableUnderline={true}
          type="text"
          sx={{
            marginY: "1rem",
            width: "75%",
            fontSize: "1.5rem",
            border: "1px solid grey",
            borderRadius: "0.25rem",
            paddingX: "1rem",
            paddingY: "0.5rem",
          }}
          value={length}
          onChange={(e) => setlength(e.target.value)}
          placeholder=" post length 50-500"
        />
      </Box>
      <Box mt={2}>
        <label>sample</label>
        <Input
          disableUnderline={true}
          sx={{
            width: "75%",
            fontSize: "1.5rem",
            border: "1px solid grey",
            borderRadius: "0.25rem",
            paddingX: "0.5rem",
            paddingY: "0.5rem",
          }}
          type="text"
          value={sample}
          onChange={(e) => setsample(e.target.value)}
          placeholder="number of sample"
        />
      </Box>
      <Box mt={2}>
        <Button onClick={generateText} variant="contained">
          {" "}
          Generate Text
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">
          AI will generate your text Here ....
        </Typography>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        result.map((data) => {
          return (
            <Box
              sx={{
                border: "1px solid black",
                width: "400px",
                padding: "10px",
                borderRadius: "10px",
                height: "auto",
                overflow: "auto",
              }}
              mt={2}
            >
              <Typography variant="body2">{data}</Typography>
            </Box>
          );
        })
      )}
    </Box>
  );
};
export default TextGenerator;
