import * as React from "react";

const TalentIcon = (props: any) => (
  <svg width={props.size} height={props.size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.49967 11.014L11.6197 13.5007L10.5263 8.81398L14.1663 5.66065L9.37301 5.25398L7.49967 0.833984L5.62634 5.25398L0.833008 5.66065L4.47301 8.81398L3.37967 13.5007L7.49967 11.014Z"
      fill={props.color}
    />
  </svg>
);

export default TalentIcon;
