import { configureStore } from "@reduxjs/toolkit";
import onboardingReducer from "./onboardingSlice/onboardingSlice";
import PostReducer from "./commandSlice/posts/PostReducer";
import feedReducer from "./feedSlice/feedSlice";
import AcademyReducer from "./academySlice/academySlice";
import adminReducer from "./adminSlice/adminSlice";

export const store = configureStore({
  reducer: {
    onboarding: onboardingReducer,
    posts: PostReducer,
    feeds: feedReducer,
    academy: AcademyReducer,
    admin: adminReducer,
    feed: feedReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
