import { createAsyncThunk } from '@reduxjs/toolkit'
import * as academyServices from '../../core/services/academy/academyApi'

const getAllSeries = createAsyncThunk('getAllSeries', async (_, {getState}) => {
	return await academyServices.GetAllSeries();
});

export const academyActions = {
	getAllSeries,
};

