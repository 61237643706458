import { createAsyncThunk } from "@reduxjs/toolkit";
import { feedServices } from "./feedServices";

const eventsUserAttending = createAsyncThunk("eventsUserAttending", async (_, { getState }) => {
  try {
    const state: any = getState();
    const response = await feedServices.eventsUserAttendingAPI(state.onboarding.access_token);
    return response;
  } catch (error: any) {
    throw error;
  }
});

const collabUserAttending = createAsyncThunk("collabUserAttending", async (_, { getState }) => {
  try {
    const state: any = getState();
    const response = await feedServices.eventsUserAttendingAPI(state.onboarding.access_token);
    return response;
  } catch (error: any) {
    throw error;
  }
});

// const CollabUserAttending = async () => {
//   let obj;

//   let token: any = localStorage.getItem("access_token");
//   try {
//     let { data } = await axios.get(`${FeedCollabEndpoint}/upcoming`, {
//       headers: {
//         Authorization: token,
//       },
//     });
//     obj = data;
//     return obj;
//   } catch (error: any) {
//     toast.error(error.msg, { theme: "dark" });
//   }
// };

export const feedActions = {
    eventsUserAttending,
    collabUserAttending
};
