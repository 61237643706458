import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { toast } from "react-toastify";
// const REACT_APP_TEXT_TO_IMAGE_GENERATOR =
//   process.env.REACT_APP_TEXT_TO_IMAGE_GENERATOR;
const TextToImgGenerator = () => {
  // const [loading,setloading]=React.useState(false)
  const [text, settext] = React.useState("");
  const [width, setwidth] = React.useState("");
  const [height, setheight] = React.useState("");
  const [freq, setfreq] = React.useState("");
  const [iter, setiter] = React.useState("");
  const [res, setres] = React.useState<any>([]);
  const [loading, setloading] = React.useState<boolean>(false);
  const imgData = {
    prompts: text,
    img_width: width,
    img_height: height,
    frequency: freq,
    max_iter: iter,
  };
  const handleloader = async () => {
    try {
      // if (process.env.REACT_APP_TEXT_TO_IMAGE_GENERATOR) {
      setloading(true);
      const data = await axios.post(
        // process.env.REACT_APP_TEXT_TO_IMAGE_GENERATOR + "generate/image/",
        "https://ai-imagegen.dev.vnplatform.com/generate/image/",
        imgData
      );
      if (data?.data?.data?.video_generated) {
        console.log("data", data?.data?.data?.video_generated);
        setres(data?.data?.data?.video_generated);
      }
      // else console.log("error")

      setloading(false);
      // } else {
      //   toast.error("Something went wrong!", { theme: "dark" });
      // }
    } catch (err) {
      toast.error(err, { theme: "dark" });
      setloading(false);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h2">AI Image Generator</Typography>
      <TextField
        variant="filled"
        placeholder="Write the object name "
        id="filled-hidden-label-normal"
        hiddenLabel
        value={text}
        sx={{ width: "350px", marginTop: "10px" }}
        onChange={(e) => settext(e.target.value)}
      />
      <Box mt={1}>
        <label>img_width</label>
        <input
          type="text"
          style={{ padding: "7px", marginLeft: "5px" }}
          value={width}
          onChange={(e) => setwidth(e.target.value)}
          placeholder="width should be 100-500"
        />
      </Box>
      <Box mt={1}>
        <label>img_height</label>
        <input
          type="text"
          style={{ padding: "7px", marginLeft: "5px" }}
          value={height}
          onChange={(e) => setheight(e.target.value)}
          placeholder="height should should be 100-500"
        />
      </Box>
      <Box mt={1}>
        <label>frequency</label>
        <input
          type="text"
          style={{ padding: "7px", marginLeft: "5px" }}
          value={freq}
          onChange={(e) => setfreq(e.target.value)}
          placeholder="frequency should be 10-50"
        />
      </Box>
      <Box mt={1}>
        <label>max_iteration</label>
        <input
          type="text"
          style={{ padding: "7px", marginLeft: "5px" }}
          value={iter}
          onChange={(e) => setiter(e.target.value)}
          placeholder="iteration should be 10-100"
        />
      </Box>

      <Box mt={2}>
        <Button onClick={handleloader} variant="contained">
          {" "}
          Generate Animation
        </Button>
      </Box>
      <Box mt={4}>
        <Typography variant="h5">Ai will generate Animation here...</Typography>
      </Box>
      <Box>
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          res && (
            <Box mt={4}>
              <video src={res} controls />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};
export default TextToImgGenerator;
