import * as React from "react";

const AnalyticIcon = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 12.1673C0.5 12.6978 0.710714 13.2065 1.08579 13.5815C1.46086 13.9566 1.96957 14.1673 2.5 14.1673H10.5C11.0304 14.1673 11.5391 13.9566 11.9142 13.5815C12.2893 13.2065 12.5 12.6978 12.5 12.1673V5.38598C12.4997 4.85583 12.2889 4.34747 11.914 3.97265L9.362 1.41932C8.98691 1.04446 8.47829 0.833921 7.948 0.833984H2.5C1.96957 0.833984 1.46086 1.0447 1.08579 1.41977C0.710714 1.79484 0.5 2.30355 0.5 2.83398V12.1673ZM9.83333 6.83398C9.83333 6.65717 9.7631 6.4876 9.63807 6.36258C9.51305 6.23756 9.34348 6.16732 9.16667 6.16732C8.98986 6.16732 8.82029 6.23756 8.69526 6.36258C8.57024 6.4876 8.5 6.65717 8.5 6.83398V10.834C8.5 11.0108 8.57024 11.1804 8.69526 11.3054C8.82029 11.4304 8.98986 11.5007 9.16667 11.5007C9.34348 11.5007 9.51305 11.4304 9.63807 11.3054C9.7631 11.1804 9.83333 11.0108 9.83333 10.834V6.83398ZM7.16667 8.16732C7.16667 7.99051 7.09643 7.82094 6.9714 7.69591C6.84638 7.57089 6.67681 7.50065 6.5 7.50065C6.32319 7.50065 6.15362 7.57089 6.0286 7.69591C5.90357 7.82094 5.83333 7.99051 5.83333 8.16732V10.834C5.83333 11.0108 5.90357 11.1804 6.0286 11.3054C6.15362 11.4304 6.32319 11.5007 6.5 11.5007C6.67681 11.5007 6.84638 11.4304 6.9714 11.3054C7.09643 11.1804 7.16667 11.0108 7.16667 10.834V8.16732ZM4.5 9.49732C4.49912 9.32051 4.42803 9.15129 4.30238 9.02689C4.17673 8.90249 4.00681 8.8331 3.83 8.83398C3.65319 8.83487 3.48397 8.90595 3.35957 9.0316C3.23517 9.15725 3.16578 9.32717 3.16667 9.50398L3.17333 10.8373C3.17422 11.0141 3.2453 11.1833 3.37095 11.3077C3.4966 11.4321 3.66652 11.5015 3.84333 11.5007C4.02014 11.4998 4.18936 11.4287 4.31376 11.303C4.43816 11.1774 4.50755 11.0075 4.50667 10.8307L4.5 9.49732Z"
        fill={props.color}
      />
    </svg>
  );
};

export default AnalyticIcon;
