import axios from "axios";
import {
  AuthEndPoint,
  OnboardingEndPoint,
  SigninEndPoint,
  SuperAdminEndPoint,
  UserEndPoint,
  UserSettingsEndPoint,
  SocialEndPoint,
  UserSettingsRedirectUrl,
  ChangeUserStatusEndpoint,
  DeleteUserEndpoint,
  UserRoleEndpoint,
  EmailEndPoint,
} from "../../core/constants/Api";

const REACT_APP_USER_API_URL = process.env.REACT_APP_USER_API_URL;

const setAccessTokenAPI = async (token: string) => {
  return token;
};

const setShowWeblayoutAPI = async (bool: boolean) => {
  return bool;
};

const emailSignupAPI = async (email: any, password: any) => {
  try {
    const { data } = await axios.post(REACT_APP_USER_API_URL + "auth/signup/email", {
      email: email,
      password: password,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const emailSigninAPI = async (email: any, password: any) => {
  try {
    const { data } = await axios.post(REACT_APP_USER_API_URL + "auth/signin/email", {
      email: email,
      password: password,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const resetPasswordAPI = async (email: any) => {
  try {
    const { data } = await axios.post(REACT_APP_USER_API_URL + "auth/resetpassword", {
      email: email,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getUserProfileAPI = async (token: string) => {
  try {
    const { data } = await axios.post(REACT_APP_USER_API_URL + "user/settings/getprofile", {
      access_token: token,
    });
    return data.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getUserInfoByIdAPI = async (access_token, user_id) => {
  try {
    const { data } = await axios.get(`${REACT_APP_USER_API_URL}users/all/${user_id}`, {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const changeThemeAPI = async (accessToken: any, themeId: any) => {
  try {
    const { data } = await axios.put(
      REACT_APP_USER_API_URL + "user/settings/theme",
      {
        theme_id: themeId,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const changePasswordAPI = async (email: any, password: any, key: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/changepassword", {
      email: email,
      password: password,
      key: key,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getUserInfoAPI = async (userID) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const { data } = await axios.get(`${UserEndPoint}/all/${userID}`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const onboardPaymentAPI = async (cardnumber: any, name: any, month: any, year: any, ccv: any, access_token: any) => {
  try {
    const { data } = await axios.post(OnboardingEndPoint + "/payment", {
      name_on_card: name,
      card_exp_year: parseInt(year),
      card_exp_month: parseInt(month),
      card_cvc: ccv,
      access_token: access_token,
      card_number: cardnumber,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const savePlanAPI = async (plan_id: number, access_token: any) => {
  try {
    const { data } = await axios.post(OnboardingEndPoint + "/selectplan", {
      access_token: access_token,
      plan_id: plan_id,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const getPlanAPI = async (access_token: any) => {
  try {
    const { data } = await axios.post(OnboardingEndPoint + "/getplan", {
      access_token: access_token,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const saveProfileAPI = async (
  access_token: any,
  firstname: any,
  lastname: any,
  gender_id: any,
  languages: any,
  year_of_birth: any,
  profile_pic_url: any,
  cityid: any
) => {
  try {
    const { data } = await axios.post(
      OnboardingEndPoint + "/profile",
      {
        first_name: firstname,
        last_name: lastname,
        gender_id: gender_id,
        languages: languages,
        year_of_birth: year_of_birth,
        city_id: cityid,
        profile_pic_url: profile_pic_url,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

async function getProfileAPI(access_token: any) {
  try {
    const { data } = await axios.get(OnboardingEndPoint + "/profile", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
}

const googleSignupAPI = async (tokenId: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/google/login", {
      tokenId: tokenId,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const googleSigninAPI = async (tokenId: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/google/login", {
      tokenId: tokenId,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const appleSignupAPI = async (tokenId: any) => {
  try {
    const res = await axios.post(AuthEndPoint + "/apple/login", {
      tokenId: tokenId,
    });
    return res;
  } catch (error: any) {
    throw error.response.data;
  }
};

const appleSigninAPI = async (tokenId: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/apple/login", {
      tokenId: tokenId,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const facebookSignupAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/facebook/login", {
      fb_token: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const facebookSigninAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/facebook/login", {
      fb_token: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const FacebookConnectSocialAPI = async (accessToken: any, fbToken: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/facebook", {
      access_token: accessToken,
      fb_token: fbToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const InstagramConnectSocialAPI = async (accessToken: any, fbToken: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/instagram", {
      access_token: accessToken,
      fb_token: fbToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const GetTwitterAuthTokensAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/twitter/request_token", {
      access_token: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const GetTwitterAuthTokensUserSettingsAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(
      AuthEndPoint + "/twitter/request_token",
      {
        access_token: accessToken,
      },
      {
        params: {
          callback_url: UserSettingsRedirectUrl,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const TwitterConnectSocialAPI = async (accessToken: any, oauthtoken: any, oauthverifier: any) => {
  try {
    const { data } = await axios.post(
      AuthEndPoint + "/twitter/access_token",
      {
        access_token: accessToken,
      },
      {
        params: {
          oauth_token: oauthtoken,
          oauth_verifier: oauthverifier,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const GetYoutubeAuthTokensAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/youtube/auth_url", {
      access_token: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const GetYoutubeAuthTokensUserSettingsAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(
      AuthEndPoint + "/youtube/auth_url",
      {
        access_token: accessToken,
      },
      {
        params: {
          callback_url: UserSettingsRedirectUrl,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const YoutubeConnectSocialAPI = async (accessToken: any, code: any) => {
  try {
    const { data } = await axios.post(AuthEndPoint + "/youtube/access_token", {
      access_token: accessToken,
      code: code,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const saveAboutAPI = async (access_token: any, about: string, goals: any, socials: any, contents: any, user_type_id: any) => {
  try {
    const { data } = await axios.post(OnboardingEndPoint + "/describe", {
      about: about,
      goals: goals,
      socials: socials,
      contents: contents,
      access_token: access_token,
      user_type_id: user_type_id,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getLocationSearchAPI = async (access_token: any, searchText: string) => {
  try {
    const { data } = await axios.get(OnboardingEndPoint + "/locations/search?q=" + searchText, {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getConnectedSocialsAPI = async (access_token: any) => {
  try {
    const { data } = await axios.get(UserSettingsEndPoint + "/socials", {
      headers: {
        Authorization: access_token,
      },
    });
    console.log(data.data);
    return data.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const DeleteConnectedSocialAPI = async (access_token: any, id: any) => {
  try {
    const { data } = await axios.delete(SocialEndPoint + "/" + id, {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getLogHistoryAPI = async (access_token: any) => {
  try {
    const { data } = await axios.get(UserSettingsEndPoint + "/sessions", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getPaymentHistoryAPI = async (access_token: any) => {
  try {
    const { data } = await axios.get(UserSettingsEndPoint + "/payments", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getPaymentCardsAPI = async (access_token: any) => {
  try {
    const { data } = await axios.get(UserSettingsEndPoint + "/cards", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const DeletePaymentMethodAPI = async (access_token: any, id: any) => {
  try {
    const { data } = await axios.delete(UserSettingsEndPoint + "/cards/" + id, {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const MakePaymentMethodPrimaryAPI = async (access_token: any, id: any) => {
  try {
    const { data } = await axios.post(
      UserSettingsEndPoint + "/cards/" + id + "/primary",
      {
        is_primary: 1,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const AddPaymentMethodAPI = async (cardnumber: any, name: any, month: any, year: any, ccv: any, access_token: any) => {
  try {
    const { data } = await axios.post(
      UserSettingsEndPoint + "/cards",
      {
        name_on_card: name,
        card_exp_year: parseInt(year),
        card_exp_month: parseInt(month),
        card_number: cardnumber,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const UpdatePaymentMethodAPI = async (id: any, name: any, month: any, year: any, access_token: any) => {
  try {
    const { data } = await axios.put(
      UserSettingsEndPoint + "/cards/" + id,
      {
        name_on_card: name,
        card_exp_year: parseInt(year),
        card_exp_month: parseInt(month),
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const ChangeProfilePictureAPI = async (access_token: any, val: any) => {
  try {
    const { data } = await axios.put(
      UserSettingsEndPoint + "/picture",
      {
        profile_pic_url: val,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const saveInstagramTokenAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(UserEndPoint, {
      accessToken: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const saveFBTokenAPI = async (atoken: any, accessToken: any) => {
  try {
    const { data } = await axios.post(UserEndPoint, {
      atoken: atoken,
      accessToken: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getUserOnboardingStatusAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(OnboardingEndPoint + "/status", {
      access_token: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

//Super Admin - User settings API calls
const getUserProfileSuperAdminAPI = async (accessToken: any, id: any) => {
  try {
    const { data } = await axios.get(UserEndPoint + "/" + id, {
      headers: {
        Authorization: accessToken,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const ChangeProfilePictureSuperAdminAPI = async (access_token: any, val: any, id: any) => {
  try {
    const { data } = await axios.put(
      UserEndPoint + "/" + id + "/picture",
      {
        profile_pic_url: val,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const addUserEmailSuperAdminAPI = async (access_token: any, email: any, id: any) => {
  try {
    const { data } = await axios.post(
      UserEndPoint + "/" + id + "/emails",
      {
        email: email,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const verifyAddedEmailSuperAdminAPI = async (access_token: any, email: any, key: any, id: any) => {
  try {
    const { data } = await axios.post(
      UserEndPoint + "/" + id + "/emails/verify",
      {
        email: email,
        key: key,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const makeEmailPrimarySuperAdminAPI = async (access_token: any, emailId: any, id: any) => {
  try {
    const { data } = await axios.post(
      UserEndPoint + "/" + id + "/emails/" + emailId + "/primary",
      {},
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const settingsChangePasswordSuperAdminAPI = async (accessToken: any, password: any, newPassword1: any, newPassword2: any, id: any) => {
  try {
    const { data } = await axios.put(
      UserEndPoint + "/" + id + "/password",
      {
        password: password,
        new_password: newPassword1,
        confirm_password: newPassword2,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const UpdateLocationSuperAdminAPI = async (accessToken: any, location: any, id: any) => {
  try {
    const { data } = await axios.put(
      UserEndPoint + "/" + id + "/location",
      {
        city_id: location,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const UpdateRolesSuperAdminAPI = async (access_token: any, val: any, id: any) => {
  try {
    const { data } = await axios.put(
      UserRoleEndpoint + "superadmin/user/" + id + "/roles",
      {
        roles: val,
      },
      {
        headers: {
          Authorization: access_token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const GetRolesSuperAdminAPI = async (access_token: any, id: any) => {
  try {
    const { data } = await axios.get(UserRoleEndpoint + "superadmin/user/" + id + "/roles", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getLocationSuperAdminAPI = async (accessToken: any, id: any) => {
  try {
    const { data } = await axios.get(UserEndPoint + "/" + id + "/location", {
      headers: {
        Authorization: accessToken,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getConnectedSocialSuperAdminAPI = async (access_token: any, id: any) => {
  try {
    const { data } = await axios.get(UserEndPoint + "/" + id + "/socials", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const DeleteConnectedSocialSuperAdminAPI = async (access_token: any, Socialid: any, id: any) => {
  try {
    const { data } = await axios.delete(UserEndPoint + "/" + id + "/socials/" + Socialid, {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getLogHistorySuperAdminAPI = async (access_token: any, id: any) => {
  try {
    const { data } = await axios.get(UserEndPoint + "/" + id + "/logins", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const addUserEmailAPI = async (accessToken: any, email: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/addemail", {
      access_token: accessToken,
      email: email,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const sendEmailReffralAPI = async (accessToken: any, email: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/sendreferral", {
      access_token: accessToken,
      email: email,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const verifyAddedEmailAPI = async (accessToken: any, email: any, key: any) => {
  try {
    console.log(UserSettingsEndPoint + "/verifyaddedemail");
    const { data } = await axios.post(UserSettingsEndPoint + "/verifyaddedemail", {
      access_token: accessToken,
      email: email,
      key: key,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const addPhoneNumberAPI = async (accessToken: any, phone: any, code: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/addphonenumber", {
      access_token: accessToken,
      phone_number: phone,
      country_code_id: parseInt(code),
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const ChangePhoneNumberAPI = async (accessToken: any, phone: any, country_id: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/changephonenumber", {
      access_token: accessToken,
      phone_number: phone,
      country_id: parseInt(country_id),
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const VerifyAddPhoneNumberAPI = async (accessToken: any, phone: any, code: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/verifyaddphonenumber", {
      access_token: accessToken,
      phone_number: phone,
      phone_code: parseInt(code),
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const VerifyChangePhoneNumberAPI = async (accessToken: any, phone: any, code: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/verifychangephonenumber", {
      access_token: accessToken,
      phone_number: phone,
      phone_code: parseInt(code),
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const settingsChangePasswordAPI = async (accessToken: any, password: any, newPassword1: any, newPassword2: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/changepassword", {
      access_token: accessToken,
      password: password,
      new_password: newPassword1,
      confirm_password: newPassword2,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const makeEmailPrimaryAPI = async (accessToken: any, emailId: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/makeemailprimary", {
      access_token: accessToken,
      email_id: emailId,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const changeLocationAPI = async (accessToken: any, location: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/changelocation", {
      access_token: accessToken,
      location: location,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getThemeAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.get(UserSettingsEndPoint + "/theme", {
      headers: {
        Authorization: accessToken,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getLocationAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.get(UserSettingsEndPoint + "/location", {
      headers: {
        Authorization: accessToken,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const UpdateLocationAPI = async (accessToken: any, cityID: any) => {
  try {
    const { data } = await axios.put(
      UserSettingsEndPoint + "/location",
      {
        city_id: cityID,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const UpgradeSubscriptionAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.post(UserSettingsEndPoint + "/upgradesubscription", {
      access_token: accessToken,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

//Super admin api calls

const getLoginDataAPI = async (accessToken, start_date, last_date, key) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/logindata", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
        filter_key: +key,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getSignupByCountryAPI = async (accessToken, start_date, last_date) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bycountry", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getSignupByCityAPI = async (accessToken, start_date, last_date) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bycity", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getSignupByGenderAPI = async (accessToken, start_date, last_date) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bygender", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getSignupByVerticalAPI = async (accessToken, start_date, last_date) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/byvertical", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getSignupByLeadsAPI = async (accessToken, start_date, last_date) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/bylead", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getSignupByAgeAPI = async (accessToken, start_date, last_date) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/signup/byagegroup", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        start_date: start_date,
        end_date: last_date,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getGetActiveUsersAPI = async (accessToken: any) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/users/active", {
      headers: {
        Authorization: accessToken,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getRevenueAPI = async (accessToken: any, month: any, year: any) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/revenue", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        month: month,
        year: year,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getAllUsersAPI = async (accessToken, year, month, week, rollbackInfo) => {
  try {
    const { data } = await axios.get(SuperAdminEndPoint + "/data/allusers", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        week: week,
        month: month,
        year: year,
        rollback_by: rollbackInfo.rollbackType,
        rollback_num: rollbackInfo.rollbackNum,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const LandingPageVisitorCounterAPI = async () => {
  try {
    console.log(OnboardingEndPoint + "/visiter/add");
    const { data } = await axios.post(OnboardingEndPoint + "/visiter/add");
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

// send a varification code according to step-method
const SendTwoWayAuthCodeAPI = async (token, requestData) => {
  try {
    const { data } = await axios.post(`${SigninEndPoint}/by/2factor-auth`, requestData, { headers: { Authorization: token } });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

// verify a varification code according to step-method
const VerifyTwoWayAuthCodeAPI = async (token, requestData) => {
  let obj;
  try {
    const { data, status } = await axios.post(`${SigninEndPoint}/verify/2factor-auth`, requestData, { headers: { Authorization: token } });
    obj = { data, status };
    return obj;
  } catch (error: any) {
    throw error.response.data;
  }
};

// Set up default authentication method
const SetDefaultAuthVerifyAPI = async (value) => {
  let obj;
  const token: any = localStorage.getItem("access_token");
  try {
    const { data, status } = await axios.put(
      `${UserSettingsEndPoint}/set-as-default/2factor/auth`,
      { isTwoFactorAuth: value },
      { headers: { Authorization: token } }
    );
    obj = { data, status };
    return obj;
  } catch (error: any) {
    throw error.response.data;
  }
};

const getAboutPageDataAPI = async (token) => {
  try {
    const { data } = await axios.get(`${OnboardingEndPoint}/aboutUser`, { headers: { Authorization: token } });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const changeUserStatusAPI = async (userId, isActive) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const { data } = await axios.put(
      `${ChangeUserStatusEndpoint}/${userId}/user_activate`,
      {
        is_active: isActive ? 1 : 0,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const deleteUserAPI = async (userId) => {
  const token: any = localStorage.getItem("access_token");
  try {
    const { data } = await axios.delete(`${DeleteUserEndpoint}/${userId}`, {
      headers: {
        Authorization: token,
      },
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

const isEmailExistAPI = async (email: any) => {
  let obj;
  try {
    const { data } = await axios.get(EmailEndPoint + "/exist", {
      params: {
        email: email,
      },
    });
    const obj = data;
    return obj;
  } catch (error: any) {
    obj = error.response.data;
    return obj;
  }
};

const emailVerifyAPI = async (email: any, key: any) => {
  try {
    const { data } = await axios.post(EmailEndPoint + "/verify", {
      email: email,
      key: key,
    });
    const obj = data;
    return obj;
  } catch (error: any) {
    throw error.response.data;
  }
};

const onboardingServices = {
  emailSigninAPI,
  getUserProfileAPI,
  getUserInfoByIdAPI,
  resetPasswordAPI,
  changeThemeAPI,
  setAccessTokenAPI,
  setShowWeblayoutAPI,
  emailSignupAPI,
  sendEmailReffralAPI,
  SendTwoWayAuthCodeAPI,
  VerifyTwoWayAuthCodeAPI,
  deleteUserAPI,
  changeUserStatusAPI,
  ChangePhoneNumberAPI,
  changeLocationAPI,
  getAllUsersAPI,
  getSignupByAgeAPI,
  getSignupByCityAPI,
  getSignupByCountryAPI,
  getSignupByGenderAPI,
  getSignupByLeadsAPI,
  getSignupByVerticalAPI,
  getRevenueAPI,
  UpgradeSubscriptionAPI,
  getLoginDataAPI,
  getLogHistoryAPI,
  getLogHistorySuperAdminAPI,
  getAboutPageDataAPI,
  getLocationAPI,
  makeEmailPrimaryAPI,
  settingsChangePasswordAPI,
  verifyAddedEmailAPI,
  verifyAddedEmailSuperAdminAPI,
  VerifyAddPhoneNumberAPI,
  VerifyChangePhoneNumberAPI,
  getThemeAPI,
  addUserEmailAPI,
  SetDefaultAuthVerifyAPI,
  LandingPageVisitorCounterAPI,
  getGetActiveUsersAPI,
  UpdateLocationAPI,
  addPhoneNumberAPI,
  DeleteConnectedSocialSuperAdminAPI,
  getLocationSuperAdminAPI,
  GetRolesSuperAdminAPI,
  getConnectedSocialSuperAdminAPI,
  changePasswordAPI,
  getUserInfoAPI,
  onboardPaymentAPI,
  savePlanAPI,
  getPlanAPI,
  getProfileAPI,
  saveProfileAPI,
  googleSigninAPI,
  googleSignupAPI,
  appleSigninAPI,
  appleSignupAPI,
  facebookSigninAPI,
  facebookSignupAPI,
  FacebookConnectSocialAPI,
  InstagramConnectSocialAPI,
  TwitterConnectSocialAPI,
  GetTwitterAuthTokensAPI,
  GetTwitterAuthTokensUserSettingsAPI,
  GetYoutubeAuthTokensAPI,
  GetYoutubeAuthTokensUserSettingsAPI,
  YoutubeConnectSocialAPI,
  saveAboutAPI,
  getLocationSearchAPI,
  getConnectedSocialsAPI,
  getPaymentCardsAPI,
  getPaymentHistoryAPI,
  DeletePaymentMethodAPI,
  MakePaymentMethodPrimaryAPI,
  DeleteConnectedSocialAPI,
  AddPaymentMethodAPI,
  UpdatePaymentMethodAPI,
  ChangeProfilePictureAPI,
  saveInstagramTokenAPI,
  saveFBTokenAPI,
  getUserOnboardingStatusAPI,
  getUserProfileSuperAdminAPI,
  ChangeProfilePictureSuperAdminAPI,
  addUserEmailSuperAdminAPI,
  makeEmailPrimarySuperAdminAPI,
  UpdateRolesSuperAdminAPI,
  UpdateLocationSuperAdminAPI,
  settingsChangePasswordSuperAdminAPI,
  isEmailExistAPI,
  emailVerifyAPI,
};
export default onboardingServices;
