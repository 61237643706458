import { createSlice } from "@reduxjs/toolkit";
import { adminActions } from "./adminActions";
import { toast } from "react-toastify";

type stateType = {
  data: any;
  isLoading: boolean;
  error: string;
};

const stateData: stateType = {
  data: null,
  isLoading: false,
  error: "",
};

export type AdminState = {
  allUserLoginData: stateType;
  signupByCountryData: stateType;
  signupByCityData: stateType;
  signupByGenderData: stateType;
  signupByVerticalData: stateType;
  signupByLeadsData: stateType;
  signupByAgeGroupData: stateType;
  userRoleById: stateType;
  activeUsers: stateType;
  revenueData: stateType;
  usersList: stateType;
};

const initialState: AdminState = {
  allUserLoginData: stateData,
  signupByCountryData: stateData,
  signupByCityData: stateData,
  signupByGenderData: stateData,
  signupByVerticalData: stateData,
  signupByLeadsData: stateData,
  signupByAgeGroupData: stateData,
  userRoleById: stateData,
  activeUsers: stateData,
  revenueData: stateData,
  usersList: stateData,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    //******************getLoginData api call fullfilled, pending and rejected handlers***************
    [adminActions.getLoginData.fulfilled.toString()]: (state, action) => {
      state.allUserLoginData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getLoginData.pending.toString()]: (state) => {
      state.allUserLoginData.isLoading = true;
    },
    [adminActions.getLoginData.rejected.toString()]: (state, action) => {
      state.allUserLoginData.isLoading = false;
      state.allUserLoginData.error = `Error getting daily engagement activity data: ${action.error.message}`;
    },
    //******************getSignupByCountry api call fullfilled, pending and rejected handlers***************
    [adminActions.getSignupByCountry.fulfilled.toString()]: (state, action) => {
      state.signupByCountryData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getSignupByCountry.pending.toString()]: (state) => {
      state.signupByCountryData.isLoading = true;
    },
    [adminActions.getSignupByCountry.rejected.toString()]: (state, action) => {
      state.signupByCountryData.isLoading = false;
      state.signupByCountryData.error = `Error getting signup by country data: ${action.error.message}`;
      toast.error(`Error getting signup by country data: ${action.error.message}`, { theme: "dark" });
    },
    //******************getSignupByCity api call fullfilled, pending and rejected handlers***************
    [adminActions.getSignupByCity.fulfilled.toString()]: (state, action) => {
      state.signupByCityData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getSignupByCity.pending.toString()]: (state) => {
      state.signupByCityData.isLoading = true;
    },
    [adminActions.getSignupByCity.rejected.toString()]: (state, action) => {
      state.signupByCityData.isLoading = false;
      state.signupByCityData.error = `Error getting signup by city data: ${action.error.message}`;
      toast.error(`Error getting signup by city data: ${action.error.message}`, { theme: "dark" });
    },
    //******************getSignupByGender api call fullfilled, pending and rejected handlers***************
    [adminActions.getSignupByGender.fulfilled.toString()]: (state, action) => {
      state.signupByGenderData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getSignupByGender.pending.toString()]: (state) => {
      state.signupByGenderData.isLoading = true;
    },
    [adminActions.getSignupByGender.rejected.toString()]: (state, action) => {
      state.signupByGenderData.isLoading = false;
      state.signupByGenderData.error = `Error getting signup by gender data: ${action.error.message}`;
      toast.error(`Error getting signup by gender data: ${action.error.message}`, { theme: "dark" });
    },
    //******************getSignupByVertical api call fullfilled, pending and rejected handlers***************
    [adminActions.getSignupByVertical.fulfilled.toString()]: (state, action) => {
      state.signupByVerticalData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getSignupByVertical.pending.toString()]: (state) => {
      state.signupByVerticalData.isLoading = true;
    },
    [adminActions.getSignupByVertical.rejected.toString()]: (state, action) => {
      state.signupByVerticalData.isLoading = false;
      state.signupByVerticalData.error = `Error getting signup by vertical data: ${action.error.message}`;
      toast.error(`Error getting signup by vertical data: ${action.error.message}`, { theme: "dark" });
    },
    //******************getSignupByLeads api call fullfilled, pending and rejected handlers***************
    [adminActions.getSignupByByLeads.fulfilled.toString()]: (state, action) => {
      state.signupByLeadsData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getSignupByByLeads.pending.toString()]: (state) => {
      state.signupByLeadsData.isLoading = true;
    },
    [adminActions.getSignupByByLeads.rejected.toString()]: (state, action) => {
      state.signupByLeadsData.isLoading = false;
      state.signupByLeadsData.error = `Error getting signup by leads data: ${action.error.message}`;
      toast.error(`Error getting signup by leads data: ${action.error.message}`, { theme: "dark" });
    },
    //******************getSignupByAgeGroup api call fullfilled, pending and rejected handlers***************
    [adminActions.getSignupByAgeGroup.fulfilled.toString()]: (state, action) => {
      state.signupByAgeGroupData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getSignupByAgeGroup.pending.toString()]: (state) => {
      state.signupByAgeGroupData.isLoading = true;
    },
    [adminActions.getSignupByAgeGroup.rejected.toString()]: (state, action) => {
      state.signupByAgeGroupData.isLoading = false;
      state.signupByAgeGroupData.error = `Error getting signup by age group data: ${action.error.message}`;
      toast.error(`Error getting signup by age group data: ${action.error.message}`, { theme: "dark" });
    },
    //******************  getUserRoleById api call fullfilled, pending and rejected handlers***************
    [adminActions.getUserRoleById.fulfilled.toString()]: (state, action) => {
      state.userRoleById = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getUserRoleById.pending.toString()]: (state) => {
      state.userRoleById.isLoading = true;
    },
    [adminActions.getUserRoleById.rejected.toString()]: (state, action) => {
      state.userRoleById.isLoading = false;
      state.userRoleById.error = `Error getting user roles by Id data: ${action.error.message}`;
      toast.error(`Error getting user roles by Id data: ${action.error.message}`, { theme: "dark" });
    },

    //******************getActiveUsers api call fullfilled, pending and rejected handlers***************
    [adminActions.getActiveUsers.fulfilled.toString()]: (state, action) => {
      state.activeUsers = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getActiveUsers.pending.toString()]: (state) => {
      state.activeUsers.isLoading = true;
    },
    [adminActions.getActiveUsers.rejected.toString()]: (state, action) => {
      state.activeUsers.isLoading = false;
      state.activeUsers.error = `Error getting active users ${action.error.message}`;
      toast.error(`Error getting active users ${action.error.message}`, { theme: "dark" });
    },
    //******************getRevenue api call fullfilled, pending and rejected handlers***************
    [adminActions.getRevenue.fulfilled.toString()]: (state, action) => {
      state.revenueData = {
        data: action.payload.data,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getRevenue.pending.toString()]: (state) => {
      state.revenueData.isLoading = true;
    },
    [adminActions.getRevenue.rejected.toString()]: (state, action) => {
      state.revenueData.isLoading = false;
      state.revenueData.error = `Error getting revenue data ${action.error.message}`;
      toast.error(`Error getting revenue data ${action.error.message}`, { theme: "dark" });
    },
    //******************getAllUsers api call fullfilled, pending and rejected handlers***************
    [adminActions.getAllUsers.fulfilled.toString()]: (state, action) => {
      state.usersList = {
        data: action.payload,
        isLoading: false,
        error: "",
      };
    },
    [adminActions.getAllUsers.pending.toString()]: (state) => {
      state.usersList.isLoading = true;
    },
    [adminActions.getAllUsers.rejected.toString()]: (state, action) => {
      state.usersList.isLoading = false;
      state.usersList.error = `Error getting users list ${action.error.message}`;
      toast.error(`Error getting users list ${action.error.message}`, { theme: "dark" });
    },
  },
});

export const adminReducers = adminSlice.actions;
export default adminSlice.reducer;
