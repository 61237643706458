import * as React from "react"

const ConfigIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1231 10.9359C17.1591 10.6359 17.1831 10.3239 17.1831 9.9999C17.1831 9.6759 17.1591 9.3639 17.1111 9.0639L19.1391 7.4799C19.3191 7.3359 19.3671 7.0719 19.2591 6.8679L17.3391 3.5439C17.2191 3.3279 16.9671 3.2559 16.7511 3.3279L14.3631 4.2879C13.8591 3.9039 13.3311 3.5919 12.7431 3.3519L12.3831 0.807902C12.3471 0.567902 12.1431 0.399902 11.9031 0.399902H8.06314C7.82314 0.399902 7.63114 0.567902 7.59514 0.807902L7.23514 3.3519C6.64714 3.5919 6.10714 3.9159 5.61514 4.2879L3.22714 3.3279C3.01114 3.2439 2.75914 3.3279 2.63914 3.5439L0.719139 6.8679C0.599139 7.0839 0.647139 7.3359 0.839139 7.4799L2.86714 9.0639C2.81914 9.3639 2.78314 9.6879 2.78314 9.9999C2.78314 10.3119 2.80714 10.6359 2.85514 10.9359L0.827139 12.5199C0.647139 12.6639 0.599139 12.9279 0.707139 13.1319L2.62714 16.4559C2.74714 16.6719 2.99914 16.7439 3.21514 16.6719L5.60314 15.7119C6.10714 16.0959 6.63514 16.4079 7.22314 16.6479L7.58314 19.1919C7.63114 19.4319 7.82314 19.5999 8.06314 19.5999H11.9031C12.1431 19.5999 12.3471 19.4319 12.3711 19.1919L12.7311 16.6479C13.3191 16.4079 13.8591 16.0839 14.3511 15.7119L16.7391 16.6719C16.9551 16.7559 17.2071 16.6719 17.3271 16.4559L19.2471 13.1319C19.3671 12.9159 19.3191 12.6639 19.1271 12.5199L17.1231 10.9359V10.9359ZM9.98314 13.5999C8.00314 13.5999 6.38314 11.9799 6.38314 9.9999C6.38314 8.0199 8.00314 6.3999 9.98314 6.3999C11.9631 6.3999 13.5831 8.0199 13.5831 9.9999C13.5831 11.9799 11.9631 13.5999 9.98314 13.5999Z"
      fill={props.color}
    />
  </svg>
)

export default ConfigIcon
