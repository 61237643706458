import { createSlice } from "@reduxjs/toolkit";
import { feedActions } from "./feedActions";

type stateType = {
  data: any;
  isLoading: boolean;
  error: string;
};

const stateData: stateType = {
  data: null,
  isLoading: false,
  error: "",
};

export type FeedState = {
  feedPosts: [];
  lastItem: {};
  hasMorePosts: boolean;
  selectedTab: number;
  eventsUserAttending: stateType;
  collabUserAttending: stateType;
};

const initialState: FeedState = {
  feedPosts: [],
  lastItem: {},
  hasMorePosts: true,
  selectedTab: 0,
  eventsUserAttending: stateData,
  collabUserAttending: stateData,
};

export const feedSlice = createSlice({
  name: "feed",
  initialState: initialState,
  reducers: {
    setFeedPosts: (state, action) => {
      console.log("🚀 ~ file: feed.tsx ~ line 10 ~ action", action);
      state.feedPosts = action.payload.items;
      if (action.payload.lastItem) {
        state.hasMorePosts = true;
        state.lastItem = action.payload.lastItem;
      } else {
        state.hasMorePosts = false;
      }
    },
    changeSocialTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: {
    //******************eventsUserAttending api call fullfilled, pending and rejected handlers***************
    [feedActions.eventsUserAttending.fulfilled.toString()]: (state, action) => {

      //retriving organized events from the API response
      let tempArr: any = [];
      for (let i = 0; i < action.payload.length; i++) {
        if (action.payload[i].feed_user_organized_events) {
          tempArr.push(action.payload[i]);
        }
      }
      state.eventsUserAttending.data = tempArr;
      state.eventsUserAttending.isLoading = false;
      state.eventsUserAttending.error = ""
    },
    [feedActions.eventsUserAttending.pending.toString()]: (state) => {
      state.eventsUserAttending.isLoading = true;
    },
    [feedActions.eventsUserAttending.rejected.toString()]: (state, action) => {
      state.eventsUserAttending.isLoading = false;
      state.eventsUserAttending.error = `Error getting events user attending: ${action.error.message}`;
    },

    //******************collabUserAttending api call fullfilled, pending and rejected handlers***************
    [feedActions.collabUserAttending.fulfilled.toString()]: (state, action) => {
      state.collabUserAttending.data = action.payload;
      state.collabUserAttending.isLoading = false;
      state.collabUserAttending.error = ""
    },
    [feedActions.collabUserAttending.pending.toString()]: (state) => {
      state.collabUserAttending.isLoading = true;
    },
    [feedActions.collabUserAttending.rejected.toString()]: (state, action) => {
      state.collabUserAttending.isLoading = false;
      state.collabUserAttending.error = `Error getting events user attending: ${action.error.message}`;
    },
  },
});

export const { setFeedPosts, changeSocialTab } = feedSlice.actions;
export default feedSlice.reducer;
