import * as React from "react"

const AcademyIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 20 13" fillOpacity={0} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.250236 4.12457L9.82584 0.0354721C9.9366 -0.0118239 10.0634 -0.0118241 10.1742 0.0354718L19.7498 4.12457C20.0834 4.26705 20.0834 4.71661 19.7498 4.85909L18.4878 5.39797V7.51231C18.4878 7.79034 18.2503 8.01573 17.9573 8.01573C17.6644 8.01573 17.4269 7.79034 17.4269 7.51231V5.85105L16.3659 6.30412V9.70587C16.3659 9.85693 16.2768 9.99529 16.1351 10.0642L10.1937 12.9556C10.0721 13.0148 9.92792 13.0148 9.80634 12.9556L3.86486 10.0642C3.72321 9.99529 3.63413 9.85693 3.63413 9.70587V6.30412L0.250236 4.85909C-0.0834121 4.71661 -0.0834121 4.26705 0.250236 4.12457Z"
      stroke={props.color}
    />
  </svg>
)

export default AcademyIcon
