import axios from "axios";
const REACT_APP_FEEDS_API_URL: any = process.env.REACT_APP_FEEDS_API_URL;

const eventsUserAttendingAPI = async (access_token: string) => {
  try {
    const { data } = await axios.get(REACT_APP_FEEDS_API_URL + "feed/events/youareattending", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

const collabUserAttendingAPI = async (access_token: string) => {
  try {
    const { data } = await axios.get(REACT_APP_FEEDS_API_URL + "feed/collab/upcoming", {
      headers: {
        Authorization: access_token,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

// const CollabUserAttending = async () => {
//   let obj;

//   let token: any = localStorage.getItem("access_token");
//   try {
//     let { data } = await axios.get(`${FeedCollabEndpoint}/upcoming`, {
//       headers: {
//         Authorization: token,
//       },
//     });
//     obj = data;
//     return obj;
//   } catch (error: any) {
//     toast.error(error.msg, { theme: "dark" });
//   }
// };

export const feedServices = { eventsUserAttendingAPI, collabUserAttendingAPI };
