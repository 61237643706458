import * as React from "react"

const HomeIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 16" fillOpacity={0} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.99119 0.517481L8 0.508017L8.00881 0.517482L8.02044 0.528471L15.4701 7.57031C15.4843 7.58919 15.5 7.62429 15.5 7.67185V15.3861C15.5 15.4391 15.4813 15.4751 15.4663 15.4923C15.4631 15.4961 15.4605 15.4985 15.4586 15.5H11.208C11.2062 15.4985 11.2036 15.4961 11.2003 15.4923C11.1854 15.4751 11.1667 15.4391 11.1667 15.3861V11.7025C11.1667 11.1557 10.7678 10.5886 10.1333 10.5886H5.86667C5.23222 10.5886 4.83333 11.1557 4.83333 11.7025V15.3861C4.83333 15.4391 4.8146 15.4751 4.79966 15.4923C4.79638 15.4961 4.7938 15.4985 4.79198 15.5H0.541357C0.539528 15.4985 0.536949 15.4961 0.533669 15.4923C0.51873 15.4751 0.5 15.4391 0.5 15.3861V7.67185C0.5 7.62429 0.515691 7.5892 0.529914 7.57031L7.97957 0.52847L7.99119 0.517481ZM15.4554 15.5022C15.4554 15.5021 15.4558 15.5019 15.4565 15.5016L15.4554 15.5022ZM11.2113 15.5022C11.2113 15.5022 11.2109 15.5021 11.2102 15.5016L11.2113 15.5022ZM4.78872 15.5022C4.78874 15.5021 4.78911 15.5019 4.78983 15.5016L4.78872 15.5022ZM0.544613 15.5022C0.544604 15.5022 0.544228 15.5021 0.543497 15.5016L0.544613 15.5022Z"
      stroke={props.color}
    />
  </svg>
)

export default HomeIcon
