import * as React from "react";

const CreditIcon = (props: any) => (
  <svg width={props.size ? props.size : 16} height={props.size ? props.size : 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.03087 0.406113L16.0002 6.19618L12.9562 15.5647H3.10554L0.0615234 6.19618L8.03087 0.406113Z" fill="#76D4F2" />
    <path d="M8.02962 0.406113L12.9176 15.5939H3.1416L8.02962 0.406113Z" fill="#3AADD2" />
    <path d="M7.96929 0.406113L0 6.19615L3.0441 15.5647H7.96929V0.406113Z" fill="#28C8FB" fillOpacity={0.52} />
  </svg>
);

export default CreditIcon;
