import * as React from "react";

const ManageIcon = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6 9.33333H10.4V10.5556H11.6V9.33333ZM11.6 6.88889H10.4V8.11111H11.6V6.88889ZM12.8 11.7778H8V10.5556H9.2V9.33333H8V8.11111H9.2V6.88889H8V5.66667H12.8V11.7778ZM6.8 4.44444H5.6V3.22222H6.8V4.44444ZM6.8 6.88889H5.6V5.66667H6.8V6.88889ZM6.8 9.33333H5.6V8.11111H6.8V9.33333ZM6.8 11.7778H5.6V10.5556H6.8V11.7778ZM4.4 4.44444H3.2V3.22222H4.4V4.44444ZM4.4 6.88889H3.2V5.66667H4.4V6.88889ZM4.4 9.33333H3.2V8.11111H4.4V9.33333ZM4.4 11.7778H3.2V10.5556H4.4V11.7778ZM8 4.44444V2H2V13H14V4.44444H8Z" fill={props.color} />
    </svg>
  );
};

export default ManageIcon;
