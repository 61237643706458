/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";

import { Box, Button, Container, IconButton, Input, InputAdornment } from "@mui/material";
// import { GetAllSeries, GetAllVideosApi } from "../../../core/services/academy/academyApi";
import SwitchUnstyled, { switchUnstyledClasses } from "@mui/base/SwitchUnstyled";
import { useDispatch, useSelector } from "react-redux";

import Avatar from "@mui/material/Avatar";
import BellIcon from "../../../assets/icons/bellIcon";
import CreditIcon from "../../../assets/icons/creditIcon";
import GirdMenuIcon from "../../../assets/icons/gridMenu";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import MainLogo from "../../../assets/icons/logo";
import MainLogo2 from "../../../assets/icons/MainLogo2";
import Menu from "@mui/material/Menu";
import MenuIcon from "../../../assets/icons/menuIcon";
import MenuItem from "@mui/material/MenuItem";
import { NavBarStyle } from "./NavBarStyles";
import NavLogo from "../../../assets/img/logo.png";
// import ProfilePic from "../../../assets/icons/images/demoPic.png";
import { Search } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import TelegramIcon from "../../../assets/icons/telegramIcon";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
// import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import onboardingActions from "../../../redux/onboardingSlice/onboardingActions";

const RootNav = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;

  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 2px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #3dacff;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export default function NavBar(props: any) {
  const dispatch = useDispatch();
  // const {userProfile} = useSelector((state: any) => state["onboarding"]);
  const theme = useTheme();
  const history = useHistory();
  const mdSize = useMediaQuery(theme.breakpoints.up("md"));

  const themeID = useSelector((state: any) => state.onboarding.appTheme.id);
  const classes = NavBarStyle.useStyle();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //! for bell icon menu state
  const [anchorEle, setAnchorEle] = React.useState<null | HTMLElement>(null);
  //! for app icon menu state
  // const [anchorElem, setAnchorElem] = React.useState<null | HTMLElement>(null);

  // const [searchSuggestions, setSerachSuggestions] = React.useState<any[]>([]);
  // const [showSearchSuggestion, setShowSearchSuggestion] = React.useState<boolean>(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = React.useState<boolean>(true);
  // const [series, setSeries] = React.useState<any[]>([]);
  const isMenuOpen = Boolean(anchorEl);
  // const [profilePicture, setprofilePic] = React.useState<string>(ProfilePic);
  const isopen = Boolean(anchorEle);
  // const Appopen = Boolean(anchorElem);
  // const [credits, setCredits] = React.useState(0);
  // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // React.useEffect(() => {
  //   getAllSeries();
  //   if (!userProfile) {
  //     history.push("/signin");
  //   } else {
  //     setCredits(userProfile.credit_balance);
  //     setprofilePic(userProfile.profile_pic_url);
  //   }
  // }, [userProfile]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEle) {
      setAnchorEle(null);
    } else {
      setAnchorEle(event.currentTarget);
    }
  };

  // const handleApp = (event: React.MouseEvent<HTMLElement>) => {
  //   if (anchorElem) {
  //     setAnchorElem(null);
  //   } else {
  //     setAnchorElem(event.currentTarget);
  //   }
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEle(null);
  };
  // const handleAppClose = () => {
  //   setAnchorElem(null);
  // };

  // const getAllSeries = async () => {
  //   try {
  //     let obj = await GetAllSeries();
  //     if (obj) {
  //       setSeries(obj.data);
  //     }
  //   } catch (error: any) {
  //     toast.error(error.msg, { theme: "dark" });
  //   }
  // };

  // const searchHandler = async (event) => {
  //   const searchString = event.target.value.toLowerCase().trim();
  //   let suggesstionsList: any = [];
  //   if (searchString) {
  //     setShowSearchSuggestion(true);
  //     let token = localStorage.getItem("access_token");
  //     let body = {
  //       // tags: null,
  //       likesRange: null,
  //       viewsRange: null,
  //       reviewsRange: null,
  //       searchString: searchString,
  //       approval: null,
  //     };

  //     let data = { body, token };

  //     // Video Searching
  //     try {
  //       let obj = await GetAllVideosApi(data);
  //       if (obj) {
  //         obj.reverse();
  //         for (let i = 0; i < obj.length; i++) {
  //           const option: any = {
  //             id: obj[i].id,
  //             thumbnail_url: obj[i].thumbnail_url,
  //             type: "video",
  //             title: obj[i].title,
  //           };
  //           suggesstionsList.push(option);
  //         }
  //       }
  //     } catch (error: any) {
  //       toast.error(error.msg, { theme: "dark" });
  //       suggesstionsList = [];
  //     }

  //     // Series Searching
  //     const temp = series.filter((item) => item.title && item.title.toLowerCase().includes(searchString));
  //     for (let j = 0; j < temp.length; j++) {
  //       const option: any = {
  //         id: temp[j].id,
  //         thumbnail_url: temp[j].thumbnail_url,
  //         type: "series",
  //         title: temp[j].title,
  //       };
  //       suggesstionsList.push(option);
  //     }
  //   } else {
  //     setShowSearchSuggestion(false);
  //   }

  //   setSerachSuggestions(suggesstionsList);
  // };

  // const handleRouting = (item) => {
  //   if (item.type === "series") {
  //     history.push(`/academy/series/${item.id}`);
  //   } else {
  //     history.push(`/academy/video/page?video_id=${item.id}`);
  //   }
  //   setSerachSuggestions([]);
  // };

  const menuToggleHandler = () => {
    const sidebarStatus = !isSideBarOpen;
    try {
      props.sidebarToggleHandler(sidebarStatus);
      if (sidebarStatus) {
        props.sidebarRef.current.style.transform = "translateX(0)";
      } else {
        props.sidebarRef.current.style.transform = "translateX(-100%)";
      }
    } catch (e) {
      console.error("[Navbar.tsx] Error occurred while toggling sidebar", e);
    }
    setIsSideBarOpen(sidebarStatus);
  };

  const GoToInbox = () => {
    history.push("/chatscreen");
    // history.push("/chatscreen");
  };

  const UpdateThemeSelection = (val: any) => {
    if (val) {
      dispatch(onboardingActions.changeTheme(1));
    } else {
      dispatch(onboardingActions.changeTheme(2));
    }
  };

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      sx={{ mt: "45px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.navMenuNav}
    >
      <MenuItem component="a" onClick={() => history.push("/settings")} className={classes.menuItemNav}>
        <SettingsIcon className={classes.navMenuIconNav} /> Settings
      </MenuItem>
      <MenuItem className={classes.menuItemNav}>
        {" "}
        <HelpOutlineIcon className={classes.navMenuIconNav} />
        Help
      </MenuItem>
      <MenuItem className={classes.menuItemNav}>
        {" "}
        <LockIcon className={classes.navMenuIconNav} />
        Privacy
      </MenuItem>
      <MenuItem className={classes.menuItemNav}>
        Dark mode{" "}
        <SwitchUnstyled
          className={classes.navMenuSwitchNav}
          component={RootNav}
          checked={themeID === 1}
          onChange={(e) => UpdateThemeSelection(e.target.checked)}
        />
      </MenuItem>
      <MenuItem component="a" onClick={() => history.push("/logout")} className={classes.menuItemNav}>
        {" "}
        <LogoutIcon className={classes.navMenuIconNav} />
        Logout
      </MenuItem>
    </Menu>
  );
  const bellId = "bell-menu";

  const bellMenu = (
    <Menu id={bellId} anchorEl={anchorEle} open={isopen} onClose={handleClose} className={classes.menuHoverColorDisable}>
      <MenuItem onClick={handleClose} sx={{ width: "500px", marginTop: "28px" }}>
        <Container>
          <Box>
            <Typography variant="h6">This Week</Typography>
          </Box>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Avatar sx={{ height: "35px", width: "35px", marginRight: "15px" }} />
            <Typography variant="body2">Sender send you request</Typography>
            <Button variant="contained" sx={{ backgroundColor: "#3DACFF", width: "12px", height: "36px", margin: "0px", marginLeft: "auto" }}>
              View
            </Button>
          </Box>
          <Box mt={2} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Avatar sx={{ height: "35px", width: "35px", marginRight: "15px" }} />
            <Typography variant="body2">Sender send you request</Typography>
            <Button variant="contained" sx={{ backgroundColor: "#3DACFF", width: "12px", height: "36px", margin: "0px", marginLeft: "auto" }}>
              Post
            </Button>
          </Box>
        </Container>
      </MenuItem>
      <MenuItem onClick={handleClose} sx={{ width: "500px", marginTop: "15px" }}>
        <Container>
          <Box>
            <Typography variant="h6">Earlier</Typography>
          </Box>
          <Box mt={3} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            <Avatar sx={{ height: "35px", width: "35px", marginRight: "15px" }} />
            <Typography variant="body2">Sender send you request</Typography>
            <Button variant="contained" sx={{ backgroundColor: "#3DACFF", width: "12px", height: "36px", margin: "0px", marginLeft: "auto" }}>
              Post
            </Button>
          </Box>
        </Container>
      </MenuItem>
    </Menu>
  );
  // const AppId = "app-menu";

  // const AppMenu = (
  //   <Menu
  //     id={AppId}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     anchorEl={anchorElem}
  //     open={Appopen}
  //     onClose={handleAppClose}
  //   >
  //     <Box
  //       style={{
  //         width: "265px",
  //         background: " rgba(255, 255, 255, 0.02)",
  //         padding: "40px 10px 10px 10px",
  //         display: "flex",
  //         justifyContent: "flex-start",
  //         alignItems: "flex-start",
  //         flexWrap: "wrap",
  //         gap: "20px",
  //       }}
  //     >
  //       <MenuItem onClick={handleAppClose}>
  //         <IconButton>
  //           <HomeIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />
  //         </IconButton>
  //       </MenuItem>
  //       <MenuItem onClick={handleAppClose}>
  //         <IconButton>
  //           <FeedIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />
  //         </IconButton>
  //       </MenuItem>
  //       <MenuItem onClick={handleAppClose}>
  //         <IconButton>
  //           <MarketIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />
  //         </IconButton>
  //       </MenuItem>
  //       <MenuItem onClick={handleAppClose}>
  //         <IconButton>
  //           <Comandicon width={20} height={20} color={"rgba(255,255,255,0.5)"} />
  //         </IconButton>
  //       </MenuItem>
  //       <MenuItem onClick={handleAppClose}>
  //         <IconButton>
  //           <AcademyIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />
  //         </IconButton>
  //       </MenuItem>
  //     </Box>
  //   </Menu>
  // );
  // const toggleSideDrawer = () => {
  //   const status = state.right;
  //   setState({ ...state, right: !status });
  // };

  return mdSize ? (
    <div className={classes.navbarNav}>
      <MenuIcon className={classes.sidebarToggleBtnNav} width={20} height={20} color={"rgba(255,255,255,0.5)"} onClick={menuToggleHandler} />
      <div className={classes.navbarContainerNav}>{props.isAcademy ? <MainLogo2 /> : <MainLogo />}</div>

      <div className={classes.serachContainerNav}>
        <div className={classes.searchNav}>
          <Input
            className={classes.searchInputNav}
            style={{ paddingRight: 5, paddingTop: 5, paddingBottom: 5 }}
            placeholder="search"
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" sx={{ width: "50px", padding: 0 }}>
                  <Search
                    style={{ color: "rgba(255, 255, 255, 0.5)", background: "#282C3A", height: "100%", width: "100%", padding: "10px", marginRight: 0 }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
          {/* <div className={classes.searchSuggestionsNav}>
            {showSearchSuggestion &&
              searchSuggestions.map((x: any) => {
                return (
                  <div className={classes.serachOptionNav} onClick={() => handleRouting(x)}>
                    <img src={x.thumbnail_url} alt={`${x.title} - Icon`} />
                    <div style={{ width: "90%" }}>
                      <Typography variant="body1" noWrap={true} style={{ fontSize: "12px" }}>
                        {" "}
                        {x.title}{" "}
                      </Typography>
                      <Typography variant="body2" noWrap={true} style={{ opacity: "0.7", textTransform: "capitalize", fontSize: "10px" }}>
                        {" "}
                        {x.type}{" "}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div> */}
        </div>
      </div>

      <div className={classes.headerRightNav}>
        {/* <GirdMenuIcon width={18} height={18} onClick={handleApp} onClose={handleAppClose} aria-controls={AppId} style={{ cursor: "pointer" }} />
        {AppMenu} */}
        <GirdMenuIcon width={18} height={18} onClick={() => props.rightSidebarToggleHandler(!props.rightSidebarState)} style={{ cursor: "pointer" }} />
        <BellIcon
          width={20}
          height={20}
          onClick={handleClick}
          onClose={handleClose}
          aria-controls={isopen ? bellId : undefined}
          aria-haspopup="true"
          aria-expanded={isopen ? "true" : undefined}
          style={{ cursor: "pointer" }}
        />
        {bellMenu}
        <TelegramIcon width={20} height={20} onClick={() => GoToInbox()} className={classes.pointer} />
        {/* <span style={{ color: "#3DACFF" }}>{credits}</span> */}
        <CreditIcon width={18} height={18} />

        {/* <Avatar
          onClick={handleProfileMenuOpen}
          aria-controls={menuId}
          aria-haspopup="true"
          alt="profilePicture"
          src={props.profilePicture ? props.profilePicture : profilePicture}
          className={classes.profileAvatarNav}
        /> */}
        {renderMenu}
        {/* <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
          {AppMenu("right")}
        </Drawer> */}
      </div>
    </div>
  ) : (
    <div className={classes.navbarMobile}>
      <MenuIcon className={classes.sidebarToggleBtnNav} width={20} height={20} color={"rgba(255,255,255,0.5)"} onClick={menuToggleHandler} />

      {/* Logo for screen width less than 600 */}
      <div className={classes.navbarContainerMobile} onClick={() => setShowSearch(false)}>
        <img src={NavLogo} alt="Logo" style={{ width: "70px", marginLeft: "40px", cursor: "pointer" }} />
      </div>
      {showSearch ? (
        <div className={classes.serachContainerNavMobile}>
          <div className={classes.searchNavMobile}>
            <Input
              className={classes.searchInputNav}
              style={{ paddingRight: 5, paddingTop: 5, paddingBottom: 5 }}
              placeholder="search"
              
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end" sx={{ width: "50px", padding: 0 }}>
                    <Search
                      style={{ color: "rgba(255, 255, 255, 0.5)", background: "#282C3A", height: "100%", width: "100%", padding: "10px", marginRight: 0 }}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
            {/* <div className={classes.searchSuggestionsNav}>
              {showSearchSuggestion &&
                searchSuggestions.map((x: any) => {
                  return (
                    <div className={classes.serachOptionNav} onClick={() => handleRouting(x)}>
                      <img src={x.thumbnail_url} alt={`${x.title} - Icon`} />
                      <div style={{ width: "90%" }}>
                        <Typography variant="body1" noWrap={true} style={{ fontSize: "12px" }}>
                          {x.title}
                        </Typography>
                        <Typography variant="body2" noWrap={true} style={{ opacity: "0.7", textTransform: "capitalize", fontSize: "10px" }}>
                          {x.type}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </div> */}
          </div>
        </div>
      ) : null}

      <div className={!showSearch ? classes.headerRightNavMobile : ""}>
        {!showSearch && (
          <Search style={{ color: "rgba(255, 255, 255, 0.5)", marginRight: "1rem", fontSize: "25px", cursor: "pointer" }} onClick={() => setShowSearch(true)} />
        )}
        {!showSearch ? (
          <>
            <GirdMenuIcon width={18} height={18} onClick={() => props.rightSidebarToggleHandler(!props.rightSidebarState)} style={{ cursor: "pointer" }} />
            <BellIcon
              width={20}
              height={20}
              onClick={handleClick}
              onClose={handleClose}
              aria-controls={isopen ? bellId : undefined}
              aria-haspopup="true"
              aria-expanded={isopen ? "true" : undefined}
              style={{ cursor: "pointer" }}
            />
            {bellMenu}
            <TelegramIcon width={20} height={20} onClick={() => GoToInbox()} className={classes.pointer} />
            {/* <span style={{ color: "#3DACFF" }}>{credits}</span> */}
            <CreditIcon width={18} height={18} />
          </>
        ) : null}

        {/* <Avatar
          onClick={handleProfileMenuOpen}
          aria-controls={menuId}
          aria-haspopup="true"
          alt="profilePicture"
          src={props.profilePicture ? props.profilePicture : profilePicture}
          className={classes.profileAvatarNav}
        /> */}
        {renderMenu}
      </div>
    </div>
  );
}
