import { useEffect, useState } from "react";

import HomeIcon from "../../../assets/icons/homeIcon";
import FeedIcon from "../../../assets/icons/feedIcon";
// import MarketIcon from "../../../assets/icons/marketIcon";
import Comandicon from "../../../assets/icons/commandIcon";
import AcademyIcon from "../../../assets/icons/academyIcon";
import AdminIcon from "../../../assets/icons/adminIcon";
import SettingsIcon from "../../../assets/icons/configIcon";
import { Box, Grid, Typography } from "@mui/material";
import { SideBarStyle } from "./SideBarStyle";
import TalentIcon from "../../../assets/icons/talentIcon";
import BuyersIcon from "../../../assets/icons/buyersIcon";
import ManageIcon from "../../../assets/icons/manageIcon";
import CampaignsIcon from "../../../assets/icons/campaignsIcon";
import AnalyticsIcon from "../../../assets/icons/analyticsIcon";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";

//Routes Added
const SuperAdminSideBardData = [
  {
    id: 1,
    name: "Home",
    icon: <HomeIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <HomeIcon width={20} height={20} color="white" />,
  },
  {
    id: 2,
    name: "Academy",
    icon: <AcademyIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <AcademyIcon width={20} height={20} color={"white"} />,
  },
  {
    id: 3,
    name: "Command",
    icon: <Comandicon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <Comandicon width={20} height={20} color="white" />,
  },
  {
    id: 4,
    name: "Feed",
    icon: <FeedIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <FeedIcon width={20} height={20} color="white" />,
  },
  {
    id: 5,
    name: "Talent",
    icon: <TalentIcon size={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <TalentIcon size={20} color="white" />,
  },
  // {
  //   id: 6,
  //   name: "Market",
  //   icon: <MarketIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
  //   activeIcon: <MarketIcon width={20} height={20} color="white" />,
  // },
  {
    id: 7,
    name: "Admin",
    icon: <AdminIcon width={18} height={30} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <AdminIcon width={18} height={30} color={"white"} />,
  },
  {
    id: 8,
    name: "Config",
    icon: <SettingsIcon width={18} height={30} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <SettingsIcon width={18} height={30} color={"white"} />,
  },
];

const OtherSideBardData = [
  {
    id: 1,
    name: "TextGen",
    icon: <HomeIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <HomeIcon width={20} height={20} color="white" />,
  },
  {
    id: 2,
    name: "ImgGen",
    icon: <AcademyIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <AcademyIcon width={20} height={20} color={"white"} />,
  },
  {
    id: 3,
    name: "Txt2Img",
    icon: <Comandicon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <Comandicon width={20} height={20} color="white" />,
  },
  {
    id: 4,
    name: "NFT",
    icon: <FeedIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <FeedIcon width={20} height={20} color="white" />,
  },
  
  // {
  //   id: 6,
  //   name: "Market",
  //   icon: <MarketIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
  //   activeIcon: <MarketIcon width={20} height={20} color="white" />,
  // },
];

const TalentSideBardData = [
  {
    id: 1,
    name: "Home",
    icon: <HomeIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <HomeIcon width={20} height={20} color="white" />,
  },
  {
    id: 5,
    name: "Talent",
    icon: <TalentIcon size={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <TalentIcon size={20} color="white" />,
  },
  {
    id: 9,
    name: "Buyers",
    icon: <BuyersIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <BuyersIcon width={20} height={20} color="white" />,
  },
  {
    id: 10,
    name: "Campaigns",
    icon: <CampaignsIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <CampaignsIcon width={20} height={20} color="white" />,
  },
  {
    id: 11,
    name: "Business",
    icon: <ManageIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <ManageIcon width={20} height={20} color="white" />,
  },
  {
    id: 12,
    name: "Analytics",
    icon: <AnalyticsIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    activeIcon: <AnalyticsIcon width={20} height={20} color="white" />,
  },
];
export default function SideBar(props: any) {
  const classes = SideBarStyle.useStyle();
  const [sideBardData, setSideBardData] = useState<any>(OtherSideBardData);
  const [changeSideBardData, setChangesideBardData] = useState<any>([]);
  //set default active tab to home page
  const [activeTabId, setactiveTabId] = useState(1);

  const history = useHistory();
  let routes = {
    1: "/textGen",
    2: "/imgGen",
    3: "/txtimg",
    4: "/nft",
    5: "/talent/dashboard",
    6: "/market",
    7: "/admin/platform",
    8: "/config",
    9: "/talent/buyers",
    10: "/talent/campaigns",
    11: "/talent/business",
    12: "/talent/analytics",
  };

  useEffect(() => {
    const role_id = Number(localStorage.getItem("role_id"));
    if (role_id === 5 && changeSideBardData.length === 0) {
      setSideBardData(SuperAdminSideBardData);
    } else if (role_id !== 5 && changeSideBardData.length === 0) {
      setSideBardData(OtherSideBardData);
    }

    if (window.location.pathname.toLowerCase() === "/home") {
      setactiveTabId(1);
    } else if (window.location.pathname.toLowerCase() === "/academy/dashboard") {
      setactiveTabId(2);
    } else if (window.location.pathname.toLowerCase() === "/command/dashboard") {
      setactiveTabId(3);
    } else if (window.location.pathname.toLowerCase() === "/feed/home") {
      setactiveTabId(4);
    } else if (window.location.pathname.toLowerCase() === "/market") {
      setactiveTabId(6);
    } else if (window.location.pathname.toLowerCase().substring(0, 6) === "/admin") {
      setactiveTabId(7);
    } else if (window.location.pathname.toLowerCase() === "/config") {
      setactiveTabId(8);
    } else if (window.location.pathname.toLowerCase() === "/talent/dashboard") {
      setactiveTabId(5);
    } else if (window.location.pathname.toLowerCase() === "/talent/buyers") {
      setactiveTabId(9);
    } else if (window.location.pathname.toLowerCase() === "/talent/campaigns") {
      setactiveTabId(10);
    } else if (window.location.pathname.toLowerCase() === "/talent/business") {
      setactiveTabId(11);
    } else if (window.location.pathname.toLowerCase() === "/talent/analytics") {
      setactiveTabId(12);
    }

  }, [activeTabId, history, changeSideBardData]);

  const OnNewSelection = async (id: any) => {
    if (id === 5 || id === 9 || id === 10 || id === 11 || id === 12) {
      if (changeSideBardData.length === 0) {
        setChangesideBardData(sideBardData);
      }
      setSideBardData(TalentSideBardData);
    } else {
      setSideBardData(changeSideBardData);
      setChangesideBardData([]);
    }
    history.push(routes[id]);
    setactiveTabId(id);
  };

  const theme = useTheme();
  const mdSize = useMediaQuery(theme.breakpoints.up("md"));

  return mdSize ? (
    <Grid container className={classes.stickySB} ref={props.sidebarRef}>
      <Grid item xs={12} className={classes.sideBarSB}>
        {sideBardData.map((item, i) => {
          return (
            // <Grid container key={item.id} className={classes.menuItemsSB} onClick={() => OnNewSelection(item.id)}>
            //   <Grid item xs={12}>
            //     <Grid container justifyContent="center" alignItems="center">
            //       <div>{activeTabId === item.id ? item.activeIcon : item.icon}</div>
            //     </Grid>
            //   </Grid>
            //   <Grid item xs={12} className={classes.menuLabelSB}>
            //     <Grid container justifyContent="center" alignItems="center">
            //       <Grid
            //         item
            //         color={activeTabId === item.id ? "white" : ""}
            //         sx={{ letterSpacing: "0.4px", fontSize: "12px", fontWeight: activeTabId === item.id ? "bold" : "" }}
            //       >
            //         {item.name}
            //       </Grid>
            //     </Grid>
            //   </Grid>
            // </Grid>
            // <Grid container key={item.id} className={classes.menuItemsSB} onClick={() => OnNewSelection(item.id)}>
            <Box className={classes.menuItemsSB} onClick={() => OnNewSelection(item.id)} key={i}>
              <Box>{activeTabId === item.id ? item.activeIcon : item.icon}</Box>
              <Typography
                sx={{
                  color: activeTabId === item.id ? "white" : "rgba(255, 255, 255, 0.5)",
                  letterSpacing: "0.4px",
                  fontSize: "12px",
                  fontWeight: activeTabId === item.id ? "bold" : "",
                  paddingTop: "5px",
                }}
              >
                {item.name}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <Grid container className={classes.stickySBMobile} ref={props.sidebarRef}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", padding: "5px 10px" }}>
        {sideBardData.map((item) => {
          return (
            <Box key={item.id} className={classes.menuItemsSBMobile} onClick={() => OnNewSelection(item.id)}>
              <Box justifyContent="center" alignItems="center">
                <div>{activeTabId === item.id ? item.activeIcon : item.icon}</div>
              </Box>
              <Box className={classes.menuLabelSB} justifyContent="center" alignItems="center">
                <Box color={activeTabId === item.id ? "white" : ""}>{item.name}</Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
}
