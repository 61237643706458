import { makeStyles } from "@mui/styles";
import Dark from "../../../core/theme/Dark";
const useStyles = makeStyles({
  navbarContainerNav: {
    width: "100px",
    marginLeft: "65px",
  },
  navbarNav: {
    height: "70px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    width: "100%",
    margin: 0,
    background: "#181818",
    zIndex: 1300,
    position: "sticky",
    top: "0",
  },
  searchNav: {
    display: "flex",
    width: "35vw",
    borderRadius: "5px",
    overflow: "hidden",
    background: "rgba(33, 33, 33, 1)",
    // border: "1px solid #424242",
  },
  searchIconNav: {
    width: "40px",
    background: "#282C3A",
    height: "100%",
  },
  searchInputNav: {
    "&.MuiInputBase-root": {
      border: "1px solid transparent !important",
      "&.Mui-focused": {
        border: "1px solid #3075cb !important",
        caretColor: "#3075cb",
        borderRadius: "4px",
        overflow: "hidden",
      },
    },
    // "&.Mui-focused": {
    //   border: "5px solid red !important",
    //   caretColor: "#3075cb",
    //   borderRadius: "4px",
    // },
    color: "white !important",
    borderRadius: "4px",
    height: "45px",
    padding: "10px 8px",
    width: "100%",
  },
  headerRightNav: {
    width: "150px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    marginRight: "50px",
  },
  headerRightNavMobile: {
    width: "200px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    marginRight: "50px",
  },
  profileAvatarNav: {
    width: "30px !important",
    height: "30px !important",
    borderRadius: "50%",
    overflow: "hidden",
    cursor: "pointer",
  },
  navMenuNav: {
    "& .css-5v69oj-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
      background: "#181A1C",
    },
  },
  menuItemNav: {
    color: "#FFFFFFB2 !important",
  },
  navMenuIconNav: {
    marginRight: "5px",
    marginTop: "-2px",
    transform: "scale(0.7)",
  },
  navMenuSwitchNav: {
    marginLeft: "10px",
  },
  serachContainerNav: {
    position: "relative",
    width: "35vw",
  },
  searchSuggestionsNav: {
    position: "absolute",
    top: "45px",
    left: "0px",
    color: "white",
    minWidth: "100%",
    maxHeight: "200px",
    overflow: "auto",
    backgroundColor: Dark.palette.card.main,
    width: "35vw",
  },
  serachOptionNav: {
    width: "100%",
    cursor: "pointer",
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Dark.palette.primary.main,
      fontWeight: "bold",
    },
    "& img": {
      width: "35px",
      height: "35px",
      objectFit: "cover",
      marginRight: "10px",
    },
  },
  sidebarToggleBtnNav: {
    cursor: "pointer",
    position: "absolute",
    top: "25px",
    left: "25px",
  },

  // Mobile CSS

  navbarMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: Dark.palette.nav.dark,
    height: "70px",
    alignItems: "center",
    padding: "0px 20px",
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  navbarContainerMobile: {},
  serachContainerNavMobile: {
    position: "relative",
    width: "100%",
    padding: "0 10px",
  },
  searchNavMobile: {
    display: "flex",
    width: "100%",
    borderRadius: "5px",
    overflow: "hidden",
    background: "rgba(33, 33, 33, 1)",
    // border: "1px solid #424242",
  },

  menuHoverColorDisable: {
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "transparent",
    },
  },
  pointer: {
    cursor: "pointer",
  },
});

export const NavBarStyle = {
  useStyle: useStyles,
};
