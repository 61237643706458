import React from "react";
import axios from "axios";

import { Box, Button, TextField, Typography } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
// const REACT_APP_GENERATE_COLLECTION = process.env.REACT_APP_GENERATE_COLLECTION;
const NftcardGenerator = () => {
  // const [loading,setloading]=React.useState(false)
  // const [shownft,setshownft]=React.useState(false)
  const [nimg, setnimg] = React.useState("");
  const [text, settext] = React.useState("");
  const [result, setresult] = React.useState<any>([]);
  const [loading, setloading] = React.useState<boolean>(false);

  const nftdetails = {
    collection_name: text,
    noi: nimg,
  };
  const handleNft = async () => {
    setloading(true);
    const data = await axios.post(
      // `${REACT_APP_GENERATE_COLLECTION}/generate/collection/`,
      "https://nft-genrator.vnplatform.net/api/v1/generate/collection/",
      nftdetails
    );
    if (data) {
      console.log("nfy", data.data.data.images);
      setresult(data.data.data.images);
      setnimg("");
      settext("");
    }
    setloading(false);
  };
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h2">Ai Nft Generator</Typography>
      <TextField
        variant="standard"
        placeholder="collection name"
        value={text}
        hiddenLabel
        sx={{ width: "300px", marginTop: "10px", height: "40px" }}
        onChange={(e) => settext(e.target.value)}
      />
      <Box mt={2} ml={-5}>
        <label>No of Image</label>
        <input
          style={{ marginLeft: "10px", padding: "7px", borderRadius: "5px" }}
          type="text"
          value={nimg}
          onChange={(e) => setnimg(e.target.value)}
          placeholder="enter the no 1-10"
        />
      </Box>

      <Box mt={2}>
        <Button onClick={handleNft} variant="contained">
          {" "}
          Generate Nft
        </Button>
      </Box>

      <Box mt={3}>
        <Typography variant="h4"> Ai will generate images here...</Typography>
      </Box>
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          rowGap: "10px",
          columnGap: "10px",
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          result &&
          result.map((item) => {
            return (
              <Box sx={{ border: "2px solid black", borderRadius: "5px" }}>
                <img
                  src={item}
                  alt="img"
                  style={{
                    objectFit: "cover",
                    height: "300px",
                    width: "300px",
                  }}
                />
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};
export default NftcardGenerator;
