import * as React from "react"

const Comandicon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 16" fillOpacity={0} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.70091 9.50154H15.4984C15.2845 11.0961 14.5674 12.5128 13.5291 13.569L9.70091 9.50154ZM8.49981 0.504725C11.7199 0.763131 14.3279 3.51297 14.5578 7.00154H8.49958V0.509038C8.49958 0.507551 8.49966 0.506112 8.49981 0.504725ZM8.4571 0.501446L8.45715 0.50145L8.4571 0.501446ZM6.08781 9.00154V9.19982L6.2237 9.34421L10.8078 14.2151C10.808 14.2154 10.809 14.2168 10.8101 14.22C10.8116 14.2243 10.8125 14.2298 10.8123 14.2351C9.76831 15.018 8.50521 15.4828 7.14088 15.501L7.14085 15.501C3.66944 15.5473 0.623832 12.5066 0.503438 8.74712C0.386843 5.10165 2.87539 2.09557 6.08781 1.58645V9.00154ZM10.8249 14.2256L10.8248 14.2257L10.8249 14.2256Z"
      stroke={props.color}
    />
  </svg>
)

export default Comandicon
