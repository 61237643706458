import * as React from "react"

const FeedIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={8.88889} height={2.66667} rx={0.5} fill={props.color} />
    <rect x={10.6665} width={5.33333} height={2.66667} rx={0.5} fill={props.color} />
    <rect y={4.44446} width={3.55556} height={2.66667} rx={0.5} fill={props.color} />
    <rect x={5.3335} y={4.44446} width={10.6667} height={2.66667} rx={0.5} fill={props.color} />
    <rect y={8.88892} width={8} height={2.66667} rx={0.5} fill={props.color} />
  </svg>
)

export default FeedIcon
