import { createSlice } from '@reduxjs/toolkit'
import { academyActions } from './academyActions'

export type AcademyState = {
	allSeries: any[];
}

const initialState: AcademyState = {
	allSeries: [],
}

export const academySlice = createSlice({
	name: 'academy',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[academyActions.getAllSeries.fulfilled.toString()]: (state, action) => {
			let PublishedSeries: any[] = [];
			const { data } = action.payload;

			if (data) {
				const series = [...data].reverse();
				series.forEach((element) => {
					if (element.approval_state_id === 1 && element.published_status_id === 2) {
						PublishedSeries.push(element);
					}
				});
				PublishedSeries = PublishedSeries.splice(0, 20);
				state.allSeries = PublishedSeries;
			}
		},

	},
});

export default academySlice.reducer;

