import * as React from "react"

const TelegramIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 19 19" fill={props.color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.368639 6.63345C-0.122093 6.46966 -0.126794 6.20515 0.37804 6.03665L18.3217 0.0479827C18.819 -0.117689 19.1039 0.160941 18.9648 0.648544L13.8375 18.6145C13.6964 19.1125 13.4097 19.1294 13.1991 18.6569L9.82041 11.0435L15.461 3.51298L7.94021 9.16088L0.368639 6.63345Z"
      fill="white"
      fillOpacity={0.5}
    />
  </svg>
)

export default TelegramIcon
