import * as React from "react";

const CampaignsIcon = (props) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.12108 2.79806C2.18159 2.62006 2.35141 2.5 2.54272 2.5H13.4576C13.6489 2.5 13.8187 2.62006 13.8792 2.79806L14.6668 5.11538V7.29487C14.6668 7.53561 14.4678 7.73077 14.2224 7.73077H13.7779V13.3974C13.7779 13.6382 13.579 13.8333 13.3335 13.8333H10.2224V10.7821C10.2224 10.5413 10.0234 10.3462 9.77794 10.3462H6.22239C5.97693 10.3462 5.77794 10.5413 5.77794 10.7821V13.8333H2.66683C2.42137 13.8333 2.22239 13.6382 2.22239 13.3974V7.73077H1.77794C1.53248 7.73077 1.3335 7.53561 1.3335 7.29487V5.11538L2.12108 2.79806Z" fill={props.color} />
    </svg>
  );
};

export default CampaignsIcon;
