import React from "react";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";

import SideBar from "./SideBar/SideBarResp";
import NavBar from "./NavBar/NavBarResp";
import RightSideBar from "./RightSideBar/RightSideBar.component";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles({
  boxWL: {
    minHeight: "700px",
  },
  contentWL: {
    marginLeft: "70px",
  },
  webContentWL: {
    flexGrow: 1,
    marginBottom: "10px",
    padding: "15px",
  },
});

export default function WebLayout(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  const mdSize = useMediaQuery(theme.breakpoints.up("md"));
  const sidebarRef = React.useRef();
  const [sidebarState, setSidebarState] = React.useState(true);
  const [rightSidebarState, setRightSidebarState] = React.useState(false);

  const sidebarToggleHandler = (status) => {
    setSidebarState(status);
  };
  const rightSidebarToggleHandler = (state) => {
    setRightSidebarState(state);
  };

  return (
    <Box className={classes.boxWL}>
      <NavBar
        sidebarRef={sidebarRef}
        sidebarToggleHandler={sidebarToggleHandler}
        rightSidebarToggleHandler={rightSidebarToggleHandler}
        rightSidebarState={rightSidebarState}
      />
      {sidebarState ? <SideBar sidebarRef={sidebarRef} /> : null}
      <Box className={mdSize && sidebarState ? classes.contentWL : ""}>
        <Box className={classes.webContentWL}>{props.children}</Box>
      </Box>
      <RightSideBar isOpen={rightSidebarState} rightSidebarToggleHandler={rightSidebarToggleHandler} />
    </Box>
  );
}
