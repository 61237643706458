import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminServices } from "./adminServices";

type loginDataType = {
  start_date: number | string;
  end_date: number | string;
  key: number | string;
};

type userDemographicDataType = {
  start_date: number | string;
  end_date: number | string;
};

type revenueDataType = {
  month: number;
  year: number;
};

type allUsersDataType = {
  year: number;
  month: number;
  week: number;
  rollbackInfo: {
    rollback_by: string;
    rollback_num: number;
  };
};

//Thunk to handle getLoginData
const getLoginData = createAsyncThunk("getLoginData", async (arg: loginDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getLoginDataAPI(token, arg.start_date, arg.end_date, arg.key);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getSignupByCountry
const getSignupByCountry = createAsyncThunk("getSignupByCountry", async (arg: userDemographicDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getSignupByCountryAPI(token, arg.start_date, arg.end_date);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getSignupByCity
const getSignupByCity = createAsyncThunk("getSignupByCity", async (arg: userDemographicDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getSignupByCityAPI(token, arg.start_date, arg.end_date);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getSignupByGender
const getSignupByGender = createAsyncThunk("getSignupByGender", async (arg: userDemographicDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getSignupByGenderAPI(token, arg.start_date, arg.end_date);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getSignupByVertical
const getSignupByVertical = createAsyncThunk("getSignupByVertical", async (arg: userDemographicDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getSignupByVerticalAPI(token, arg.start_date, arg.end_date);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getSignupByByLeads
const getSignupByByLeads = createAsyncThunk("getSignupByByLeads", async (arg: userDemographicDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getSignupByLeadsAPI(token, arg.start_date, arg.end_date);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getSignupByAgeGroup
const getSignupByAgeGroup = createAsyncThunk("getSignupByAgeGroup", async (arg: userDemographicDataType, { getState }) => {
  // const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getSignupByAgeGroupAPI(token, arg.start_date, arg.end_date);
    return response;
  } catch (error: any) {
    throw error;
  }
});

const getActiveUsers = createAsyncThunk("getActiveUsers", async (_, { getState }) => {
  //const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getActiveUsersAPI(token);
    return response;
  } catch (error: any) {
    throw error;
  }
});

const getRevenue = createAsyncThunk("getRevenue", async (arg: revenueDataType, { getState }) => {
  //const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = await adminServices.getRevenueAPI(token, arg.month, arg.year);
    return response;
  } catch (error: any) {
    throw error;
  }
});

const getAllUsers = createAsyncThunk("getAllUsers", async (arg: allUsersDataType, { getState }) => {
  //const state: any = getState();

  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state["onboarding"].access_token, arg.start_date, arg.end_date, arg.key);

    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const token: string = localStorage.getItem("access_token") as string;
    const response = adminServices.getAllUsersAPI(token, arg.year, arg.month, arg.week, arg.rollbackInfo);
    return response;
  } catch (error: any) {
    throw error;
  }
});

//Thunk to handle getUserRoleById
const getUserRoleById = createAsyncThunk("getUserRoleById", async (role_id: number, { getState }) => {
  
  try {
    //ACTUAL way to perform api call using access token from redux state
    //const response = await adminServices.getLoginDataAPI(state[""].access_token, arg.start_date, arg.end_date, arg.key);
    
    //TEMPORARY way to perform api call usin access token from local storage, needs to be replaces by above code when that section
    //start working
    const state: any = getState();
    const response = await adminServices.getUserRoleByIdAPI(state.onboarding.access_token, role_id);
    return response;
  } catch (error: any) {
    throw error;
  }
});

export const adminActions = {
  getLoginData,
  getSignupByCountry,
  getSignupByCity,
  getSignupByGender,
  getSignupByVertical,
  getSignupByByLeads,
  getSignupByAgeGroup,
  getUserRoleById,
  getActiveUsers,
  getRevenue,
  getAllUsers,
};
