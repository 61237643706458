import "./App.css";
import "../src/core/style/global.scss";

// import { useDispatch, useSelector } from "react-redux";

// import Dark from "./core/theme/Dark";
// import { useEffect } from "react";
// import { OnboardingState } from "./redux/onboardingSlice/onboardingSlice";
// import onboardingActions from "./redux/onboardingSlice/onboardingActions";
// import { useHistory } from "react-router-dom";
// import { adminActions } from "./redux/adminSlice/adminActions";
import AllRoutes from "./core/routes/AllRoutes";

function App() {
  // const {access_token} = useSelector((state: {onboarding: OnboardingState}) => state.onboarding)
  // const localStRoleId: any = localStorage.getItem("role_id");
  // const dispatch = useDispatch()
  // const history = useHistory()

  // useEffect(() => {
  //   try {
  //       if (access_token !== null && access_token !== "") {
  //         // let redirectresponse = await signinRedirector(access_token);
  //         dispatch(onboardingActions.getUserProfile());
  //         dispatch(adminActions.getUserRoleById(localStRoleId))
  //         // if (redirectresponse) {
  //         //   history.push(redirectresponse);
  //         // }
  //       } else if (localStorage.getItem("access_token") !== null && localStorage.getItem("access_token") !== "") {
  //         let token = localStorage.getItem("access_token") as string;
  //         dispatch(onboardingActions.setAccessToken(token));
  //         // let redirectresponse = await signinRedirector(access_token);
  //         history.push("/home");
  //         // dispatch(onboardingActions.getUserProfile());
  //       }
  //   } catch (error: any) {
  //     throw error;
  //   }
  // }, [access_token, dispatch, history, localStRoleId]);

  return <AllRoutes />;
}

export default App;
