import { makeStyles } from "@mui/styles";
import Dark from "../../../core/theme/Dark";
const useStyles = makeStyles({
  sideBarSB: {
    position: "absolute",
    background: "#181818",
    padding: "10px 0",
    height: "100vh",
    width: "70px",
    maxWidth: "70px",
    transition: "750ms",
    "@media(max-width: 600px)": {
      display: "none",
    },
  },
  stickySB: {
    width: "70px",
    maxWidth: "70px",
    minWidth: "70px",
    height: "100%",
    top: "70px",
    left: 0,
    zIndex: 1300,
    position: "sticky",
    background: "#181818",
    "@media(max-width: 600px)": {
      display: "none",
      minWidth: 0,
    },
  },
  menuItemsSB: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    marginBottom: "20px",
    height: "50px",
    "&:hover": {
      background: "rgba(64, 118, 184, 0.15)",
    },
  },
  menuLabelSB: {
    fontSize: "10px",
    color: Dark.palette.grey[600],
  },

  //   Mobile CSS

  stickySBMobile: {
    width: "100%",
    maxHeight: "70px",
    bottom: 0,
    left: 0,
    zIndex: 1400,
    position: "fixed",
    background: "#181818",
  },
  sideBarSBMobile: {
    position: "absolute",
    background: "#181818",
    padding: "0px 10px",
    height: "70px",
    width: "100%",
    transition: "750ms",
  },
  menuItemsSBMobile: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
});

export const SideBarStyle = {
  useStyle: useStyles,
};
