//init env
const REACT_APP_USER_API_URL: any = process.env.REACT_APP_USER_API_URL;
const REACT_APP_ACADEMY_API_URL: any = process.env.REACT_APP_ACADEMY_API_URL;
const REACT_APP_COMMAND_API_URL: any = process.env.REACT_APP_COMMAND_API_URL;
const REACT_APP_PAYMENT_API_URL: any = process.env.REACT_APP_PAYMENT_API_URL;
const REACT_APP_GOOGLE_CLIENT: any = process.env.REACT_APP_GOOGLE_CLIENT;
const REACT_APP_FB_CLIENT: any = process.env.REACT_APP_FB_CLIENT;
const REACT_APP_APPLE_CLIENT: any = process.env.REACT_APP_APPLE_CLIENT;
const REACT_APP_APPLE_REDIRECT_URL: any = process.env.REACT_APP_APPLE_REDIRECT_URL;
const REACT_APP_FEEDS_API_URL: any = process.env.REACT_APP_FEEDS_API_URL;
const REACT_APP_AI_API_URL: any = process.env.REACT_APP_AI_API_URL;
const REACT_APP_MEDIA_UPLOAD_API_URL: any = process.env.REACT_APP_MEDIA_UPLOAD_API_URL;
const REACT_APP_USER_SETTINGS_REDIRECT_URL: any = process.env.REACT_APP_USER_SETTINGS_REDIRECT_URL;
const REACT_APP_MEDIA_STREAM_API_URL: any = process.env.REACT_APP_MEDIA_STREAM_API_URL;
// User API

export const UserEndPoint = REACT_APP_USER_API_URL + "users";
export const SocialEndPoint = REACT_APP_USER_API_URL + "socials";
export const SignupEndPoint = REACT_APP_USER_API_URL + "auth/signup";
export const SigninEndPoint = REACT_APP_USER_API_URL + "auth/signin";
export const OnboardingEndPoint = REACT_APP_USER_API_URL + "onboarding";
export const UserSettingsEndPoint = REACT_APP_USER_API_URL + "user/settings";
export const AuthEndPoint = REACT_APP_USER_API_URL + "auth";
export const EmailEndPoint = REACT_APP_USER_API_URL + "email";
export const SuperAdminEndPoint = REACT_APP_USER_API_URL + "superadmin";
export const GetUserSocialsEndpoint = REACT_APP_USER_API_URL + "user/settings/socials";
export const CountryEndpoint = REACT_APP_USER_API_URL + "countries";
export const MediaUploadEndpoint = REACT_APP_MEDIA_UPLOAD_API_URL + "media-upload";
export const TwoFactorEndPoint = REACT_APP_USER_API_URL + "auth/fa/";
export const ChangeUserStatusEndpoint = REACT_APP_USER_API_URL + "users";
export const DeleteUserEndpoint = REACT_APP_USER_API_URL + "users";
export const RolesEndpoint = REACT_APP_USER_API_URL + "roles/";
export const MediaStreamEndpoint = REACT_APP_MEDIA_STREAM_API_URL;
export const UserRoleEndpoint = REACT_APP_USER_API_URL;
export const UserGlobalAgeGroupEndpoint = REACT_APP_USER_API_URL + "global/table/ageGroups";
export const UserGlobalBackendRouteAccessEndpoint = REACT_APP_USER_API_URL + "global/table/backendRouteAccess";
export const UserGlobalBusinessTypeEndpoint = REACT_APP_USER_API_URL + "global/table/businessType";
export const UserGlobalCitiesEndpoint = REACT_APP_USER_API_URL + "global/table/cities";
export const UserGlobalContentTypeEndpoint = REACT_APP_USER_API_URL + "global/table/contentType";
export const UserGlobalCountriesEndpoint = REACT_APP_USER_API_URL + "global/table/countries";
export const UserGlobalCrediCardBrandsEndpoint = REACT_APP_USER_API_URL + "global/table/crediCardBrands";
export const UserGlobalCreditPlanEndpoint = REACT_APP_USER_API_URL + "global/table/creditPlan";
export const UserGlobalCreditPriceEndpoint = REACT_APP_USER_API_URL + "global/table/creditPrice";
export const UserGlobalFrontendPageAccessEndpoint = REACT_APP_USER_API_URL + "global/table/frontendPageAccess";
export const UserGlobalGendersEndpoint = REACT_APP_USER_API_URL + "global/table/genders";
export const UserGlobalGoalsEndpoint = REACT_APP_USER_API_URL + "global/table/goals";
export const UserGlobalLanguagesEndpoint = REACT_APP_USER_API_URL + "global/table/languages";
export const UserGlobalLeadsEndpoint = REACT_APP_USER_API_URL + "global/table/leads";
export const UserGlobalModulesEndpoint = REACT_APP_USER_API_URL + "global/table/modules";
export const UserGlobalOrganizationTypeEndpoint = REACT_APP_USER_API_URL + "global/table/organizationType";
export const UserGlobalPaymentMethodsEndpoint = REACT_APP_USER_API_URL + "global/table/paymentMethods";
export const UserGlobalPermissionsEndpoint = REACT_APP_USER_API_URL + "global/table/permissions";
export const UserGlobalPlansEndpoint = REACT_APP_USER_API_URL + "global/table/plans";
export const UserGlobalPlatformsEndpoint = REACT_APP_USER_API_URL + "global/table/platforms";
export const UserGlobalPlatformAppsEndpoint = REACT_APP_USER_API_URL + "global/table/platformApps";
export const UserGlobalPlatformPagesEndpoint = REACT_APP_USER_API_URL + "global/table/platformPages";

// Academy API

export const uploadVideoEndpoint = REACT_APP_ACADEMY_API_URL + "academy/video";
export const createSeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/series";
export const createNewSeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v1/series";
export const VideosEndpoint = REACT_APP_ACADEMY_API_URL + "academy/videos";
export const SeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/series";
export const GenreEndPoint = REACT_APP_ACADEMY_API_URL + "academy/genre";
export const UsersSeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v1/series/user";

export const GetVideosEndPoint = REACT_APP_ACADEMY_API_URL + "academy/getvideos";
export const GetAllVideosEndPoint = REACT_APP_ACADEMY_API_URL + "academy/allvideos";
export const GetVideoEndPoint = REACT_APP_ACADEMY_API_URL + "academy/video";
export const GetTagsEndPoint = REACT_APP_ACADEMY_API_URL + "academy/tags";
export const GetSeriesEndPoint = REACT_APP_ACADEMY_API_URL + "academy/getallseries";
export const updateSeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/editseries";
export const GetSeriesOverviewEndPoint = REACT_APP_ACADEMY_API_URL + "academy/series";
export const GetSectionDetailsEndPoint = REACT_APP_ACADEMY_API_URL + "academy/sections";
export const GetAllSeriesEndPoint = REACT_APP_ACADEMY_API_URL + "academy/series/all";
export const GetParticularPlaylistEndPoint = REACT_APP_ACADEMY_API_URL + "academy/playlist";
export const LikeSeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/serieslike";
export const GetPlaylistEndPoint = REACT_APP_ACADEMY_API_URL + "academy/newplaylist";
export const AddVideoToPlaylistEndPoint = REACT_APP_ACADEMY_API_URL + "academy/addtoplaylist";
export const AddSeriesToPlaylistEndPoint = REACT_APP_ACADEMY_API_URL + "academy/addtoplaylist/series";
export const AddNewPlaylistEndPoint = REACT_APP_ACADEMY_API_URL + "academy/newplaylist";
export const GetChallengeModuleEndPoint = REACT_APP_ACADEMY_API_URL + "academy/challengemodules";
export const GetChallengeTypesModuleEndPoint = REACT_APP_ACADEMY_API_URL + "academy/challengetypes";
export const VideoDetailsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/uploadedvideo";
export const SeriesDetailsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/uploadedseries";
export const VideoReviewsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/reviews";
export const ReplyOverReviewsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/reviewreply";
export const FeatureReviewEndpoint = REACT_APP_ACADEMY_API_URL + "academy/review";
export const LikeReviewEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likereview";
export const LikeReplyEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likereviewreply";
export const SeriesYouLikeEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likedseries";
export const VideoLikeEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likevideo";
export const InstructorLikeEndpoint = REACT_APP_ACADEMY_API_URL + "academy/instructors/";
export const LikedInstructorLikeEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likedinstructors/";
export const GetUserBadgesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v2/badges/user/";
export const SubmitAnswerEndpoint = REACT_APP_ACADEMY_API_URL + "academy/user-questions";
export const SeriesHistoryEndpoint = REACT_APP_ACADEMY_API_URL + "academy/serieshistory";
export const WatchHistoryEndpoint = REACT_APP_ACADEMY_API_URL + "academy/watchhistory";
export const LikedVideosEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likedvideos";
export const LikedCreatorsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/likedinstructors";
export const GetSeriesDetailsV1 = REACT_APP_ACADEMY_API_URL + "academy/v1/series";
export const DeleteFromPlaylistEndpoint = REACT_APP_ACADEMY_API_URL + "academy/deletefromplaylist";
export const ReorderPlaylistEndpoint = REACT_APP_ACADEMY_API_URL + "academy/updatevideoposition";
export const GetInstructorVideosEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v2/videos/user";
export const GetInstructorSeriesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v2/series/user";
export const GetChallengesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v2/challenges/user";
export const GetBadgesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/v2/badges/user";

export const DeletePlaylist = REACT_APP_ACADEMY_API_URL + "academy/deletePlaylist";
export const GetSeriesReviewsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/series";

export const updatePublishStatusEndPoint = REACT_APP_ACADEMY_API_URL + "academy/updatepublishidvideo";

export const AcademyGlobalGenereEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/genere";
export const AcademyGlobalTagsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/tags";
export const AcademyGlobalApprovalStatusEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/approvalStatus";
export const AcademyGlobalBadgesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/badges";
export const AcademyGlobalChallengeArgsEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/challengeArgs";
export const AcademyGlobalChallengeModulesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/challengeModules";
export const AcademyGlobalChallengeTypesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/challengeTypes";
export const AcademyGlobalPlaylistEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/playlist";
export const AcademyGlobalPlaylistVideosEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/playlistVideos";
export const AcademyGlobalPublishedStatusEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/publishedStatus";
export const AcademyGlobalWatchStatusEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/watchStatus";
export const AcademyGlobalResourceTypesEndpoint = REACT_APP_ACADEMY_API_URL + "academy/global/table/resourceTypes";

// Command API
export const GetAllPostsEndPoint = REACT_APP_COMMAND_API_URL + "getAllPosts";

// Payment API

export const PaymentGatewayEndPoint = REACT_APP_PAYMENT_API_URL + "payment/stripe";

// Feeds API
export const FeedCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab";
export const FeedEventsEndpoint = REACT_APP_FEEDS_API_URL + "feed/events";
export const GetYourCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/yourcollab";
export const GetFeedTagsEndPOint = REACT_APP_FEEDS_API_URL + "feed/tags";
export const SkillsEndPoint = REACT_APP_FEEDS_API_URL + "feed/skills";
export const BookmarkCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/bookmark";
export const ReportCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/report";
export const PaidCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/paid";
export const VirtualCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/virtual";
export const TeamworkCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/teamwork";
export const SearchCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/name";
export const AvailableRolesEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/roles";
export const ApplyCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collabrole";
export const CandidateApprovalEndpoint = REACT_APP_FEEDS_API_URL + "feed/collabrole/approve";
export const DeleteCollabApplicationEndpoint = REACT_APP_FEEDS_API_URL + "feed/collabrole/deleteApplication";
export const CollabApplicantListEndpoint = REACT_APP_FEEDS_API_URL + "feed/collabrole/getbyid";
export const GeneresEndpoint = REACT_APP_FEEDS_API_URL + "feed/genre";
export const YourPaidCollabEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/yourpaid";
export const YourTeamworkEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/yourteamwork";
export const YourVirtualEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/yourvirtual";
export const YourBookmarkEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/yourbookmarks";
export const FeedGlobalCollabRolesEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/collabRoles";
export const FeedGlobalCollabTypesEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/collabTypes";
export const FeedGlobalConnectionApprovalStatusEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/connectionApprovalStatus";
export const FeedGlobalEventUserStatuesEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/eventUserStatues";
export const FeedGlobalGeneresEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/generes";
export const FeedGlobalTagsEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/tags";
export const FeedGlobalSkillsEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/skills";
export const FeedGlobalReportCollabReasonsEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/reportCollabReasons";
export const FeedGlobalReportEventReasonsEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/reportEventReasons";
export const FeedGlobalReportProfileReasonsEndpoint = REACT_APP_FEEDS_API_URL + "feed/global/table/reportProfileReasons";
export const FeedCollabBookMarkEndpoint = REACT_APP_FEEDS_API_URL + "feed/collab/bookmarks";

// Social Listening API
export const SocialListeningAddOrGetTagsEndpoint = REACT_APP_FEEDS_API_URL + "feed/socialListening/tags";

// Marketplace API

//Global

export const googleClient = REACT_APP_GOOGLE_CLIENT;
export const fbApp = REACT_APP_FB_CLIENT;
export const appleClient = REACT_APP_APPLE_CLIENT;
export const appleRedirect = REACT_APP_APPLE_REDIRECT_URL;
export const UserSettingsRedirectUrl = REACT_APP_USER_SETTINGS_REDIRECT_URL;

//AI
export const AISeriesEndPoint = REACT_APP_AI_API_URL + "series/content";
export const GetKeywordRecommendEndpoint = REACT_APP_AI_API_URL + "commentperformance/keywords";
export const GetSentimentEndpoint = REACT_APP_AI_API_URL + "commentperformance/sentiment";
export const GetCommentTypeEndpoint = REACT_APP_AI_API_URL + "commentperformance/text_classify/";

//Feed Social API

export const FeedTwitterEndpoint = REACT_APP_FEEDS_API_URL + "feed/twitter";
export const FeedVNAccountEndpoint = REACT_APP_FEEDS_API_URL + "feed/vnaccount";
export const FeedYoututbeEndpoint = REACT_APP_FEEDS_API_URL + "feed/youtube";
export const FeedInstagramEndpoint = REACT_APP_FEEDS_API_URL + "feed/instagram";
export const FeedFacebookEndpoint = REACT_APP_FEEDS_API_URL + "feed/facebook";
export const FeedAllPostsEndpoint = REACT_APP_FEEDS_API_URL + "feed/allSocial";

// export const FeedAllEndPoint = REACT_APP_FEEDS_API_URL + "feed/all";
export const FeedUserToConnectEndpoint = REACT_APP_USER_API_URL + "superadmin/data/allusers";
export const FeedUserConnectRequestEndPoint = REACT_APP_FEEDS_API_URL + "feed/connection/post";
export const GetAllConnectionEndPoint = REACT_APP_FEEDS_API_URL + "feed/connection";
export const AcceptOrRejectEndPoint = REACT_APP_FEEDS_API_URL + "feed/connection/approveorreject";

export const GetUserDetailsEndpoint = OnboardingEndPoint + "/getProfileData";
export const UpdateUserDetailsEndpoint = OnboardingEndPoint + "/updateProfile";

export const GetAllRolesEndpoint = REACT_APP_USER_API_URL + "global/table/roles";

export const addNewRoleEndpoint = REACT_APP_USER_API_URL + "roles/role";
