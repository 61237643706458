import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import HomeIcon from "../../../assets/icons/homeIcon";
import FeedIcon from "../../../assets/icons/feedIcon";
import MarketIcon from "../../../assets/icons/marketIcon";
import Comandicon from "../../../assets/icons/commandIcon";
import AcademyIcon from "../../../assets/icons/academyIcon";
import { Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import { SideBarMenuItem } from "./SideBarMenuItem.component";
import AdminIcon from "../../../assets/icons/adminIcon";

import SettingsIcon from "../../../assets/icons/configIcon";
import { useEffect, useState } from "react";
import TalentIcon from "../../../assets/icons/talentIcon";
import { useHistory } from "react-router-dom";

type Anchor = "right";

export default function RightSideBar({ isOpen, rightSidebarToggleHandler }) {
  const history = useHistory();
  const anchor = "right";
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    rightSidebarToggleHandler(!isOpen);
  };

  const sideBarMenuItemsForAdmin = [
    // {
    //   id: 1,
    //   name: "",
    //   icon: <MenuIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    //   activeIcon: <MenuIcon width={20} height={20} color={"white"} />,
    // },
    {
      id: 1,
      name: "Home",
      icon: <HomeIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <HomeIcon width={20} height={30} color="white" />,
    },
    {
      id: 2,
      name: "Feed",
      icon: <FeedIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <FeedIcon width={20} height={30} color="white" />,
    },
    {
      id: 3,
      name: "Talent",
      icon: <TalentIcon size={20} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <TalentIcon size={20} color="white" />,
    },
    {
      id: 4,
      name: "Market",
      icon: <MarketIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <MarketIcon width={20} height={30} color="white" />,
    },
    {
      id: 5,
      name: "Command",
      icon: <Comandicon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <Comandicon width={20} height={30} color="white" />,
    },
    {
      id: 6,
      name: "Academy",
      icon: <AcademyIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <AcademyIcon width={20} height={30} color={"white"} />,
    },
    {
      id: 7,
      name: "Admin",
      icon: <AdminIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <AdminIcon width={20} height={30} color={"white"} />,
    },
    {
      id: 8,
      name: "Config",
      icon: <SettingsIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <SettingsIcon width={20} height={30} color={"white"} />,
    },
  ];
  const sideBarMenuItemsForOthers = [
    // {
    //   id: 1,
    //   name: "",
    //   icon: <MenuIcon width={20} height={20} color={"rgba(255,255,255,0.5)"} />,
    //   activeIcon: <MenuIcon width={20} height={20} color={"white"} />,
    // },
    {
      id: 1,
      name: "Home",
      icon: <HomeIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <HomeIcon width={20} height={30} color="white" />,
    },
    {
      id: 2,
      name: "Feed",
      icon: <FeedIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <FeedIcon width={20} height={30} color="white" />,
    },
    // {
    //   id: 3,
    //   name: "Talent",
    //   icon: <TalentIcon size={20} color={"rgba(255,255,255,0.5)"} />,
    //   activeIcon: <TalentIcon size={20} color="white" />,
    // },
    {
      id: 4,
      name: "Talent",
      icon: <MarketIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <MarketIcon width={20} height={30} color="white" />,
    },
    {
      id: 5,
      name: "Command",
      icon: <Comandicon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <Comandicon width={20} height={30} color="white" />,
    },
    {
      id: 6,
      name: "Academy",
      icon: <AcademyIcon width={20} height={30} color={"rgba(255,255,255,0.5)"} />,
      activeIcon: <AcademyIcon width={20} height={30} color={"white"} />,
    },
  ];

  const sideBarMenuListItems = [
    {
      id: 1,
      name: "Talent Solutions",
      description: "Find, attract and recruit talent",
    },
    {
      id: 2,
      name: "Sales Solutions",
      description: "Unlock sales opportunities",
    },
    {
      id: 3,
      name: "Post a job for free",
      description: "Get your job in front of quality candidates",
    },
    {
      id: 4,
      name: "Marketing Solutions",
      description: "Acquire customers and grow your business",
    },
    {
      id: 5,
      name: "Learning Solutions",
      description: "Develop talent accross your organisation",
    },
  ];
  let routes = {
    1: "/home",
    2: "/feed/home",
    4: "http://dev.talent.vnplatform.com/",
    5: "/command/dashboard",
    6: "/academy/dashboard",
    7: "/admin/platform",
    8: "/config",
    9: "/chatscreen",
  };

  const [sideBarData, setSideBarData] = useState<any>(sideBarMenuItemsForOthers);
  const [activeTabId, setactiveTabId] = useState(2);
  useEffect(() => {
    const role_id = Number(localStorage.getItem("role_id"));
    if (role_id === 5) {
      setSideBarData(sideBarMenuItemsForAdmin);
    } else {
      setSideBarData(sideBarMenuItemsForOthers);
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const Active_ID = Number(localStorage.getItem("active_ID"));
    if (Active_ID) {
      setactiveTabId(Active_ID);
    } else {
      localStorage.setItem("active_ID", "2");
    }
  }, [activeTabId]);

  const OnNewSelection = (id: any) => {
    console.log("route_id", id);
    localStorage.setItem("active_ID", id);
    if (id === 4) {
      window.location.href = routes[id];
    } else {
      history.push(routes[id]);
    }
  };

  const list = (anchor: Anchor) => (
    <Box width="320px" role="grid" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <Grid
        sx={{ border: "thin solid rgba(255, 255, 255, 0.1)", borderRadius: "4px" }}
        alignItems="center"
        justifyContent="center"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        margin={2}
      >
        <Typography fontWeight="bold" padding={1} marginBottom={1} borderBottom="thin solid rgba(255, 255, 255, 0.1)" borderRadius="4px">
          Visit More Viral Nation Products
        </Typography>
        <Grid container xs={12} spacing={1}>
          {sideBarData.map((obj) => (
            <SideBarMenuItem
              key={obj.id}
              id={obj.id}
              Icon={obj.id === activeTabId ? obj.activeIcon : obj.icon}
              text={obj.name}
              handleClick={OnNewSelection}
              isActive={obj.id === activeTabId ? true : false}
            />
          ))}
        </Grid>
      </Grid>
      <Grid
        sx={{ border: "thin solid rgba(255, 255, 255, 0.1)", borderRadius: "4px" }}
        alignItems="center"
        justifyContent="center"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        margin={2}
      >
        <Typography fontWeight="bold" padding={1} marginBottom={1} borderBottom="thin solid rgba(255, 255, 255, 0.1)" borderRadius="4px">
          Viral Nation Business Services
        </Typography>
        <List>
          {sideBarMenuListItems.map((obj) => (
            <ListItem sx={{ paddingBottom: 0, paddingTop: 0 }}>
              <ListItemButton sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)", color: "white" } }}>
                <ListItemText primary={obj.name} secondary={obj.description} secondaryTypographyProps={{ color: "rgba(255, 255, 255, 0.5)" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Box>
  );

  return (
    <Drawer sx={{ zIndex: 1400 }} anchor={anchor} open={isOpen} onClose={toggleDrawer(anchor, false)}>
      {list(anchor)}
    </Drawer>
  );
}
