import { createAsyncThunk } from "@reduxjs/toolkit";
import onboardingServices from "./onboardingServices";


  const emailSignin = createAsyncThunk("emailSignin", async (arg: {email: string, password: string}) => {
    try {
      const response = await onboardingServices.emailSigninAPI(arg.email, arg.password);
      if (response.data) {
        localStorage.setItem("access_token", response.data.access_token)
      }
      return response.data;
    } catch (error: any) {
      throw error
    }
  });

  const getUserInfoById = createAsyncThunk("getUserInfoById", async (user_id: number, {getState}) => {
    try {
      const state: any = getState();
      const response = await onboardingServices.getUserInfoByIdAPI(state.onboarding.access_token, user_id);
      return response;
    } catch (error: any) {
      throw error
    }
  });

  const googleSignUp = createAsyncThunk("googleSignUp", async (tokenId: any) => {
    try {
      const response = await onboardingServices.googleSignupAPI(tokenId)
      if (response.data) {
        localStorage.setItem("access_token", response.data.access_token)
      }
      return response;
    } catch (error: any) {
      throw error
    }
  });

  const facebookSignUp = createAsyncThunk("facebookSignUp", async (tokenId: any) => {
    try {
      const response = await onboardingServices.facebookSignupAPI(tokenId)
      if (response.data) {
        localStorage.setItem("access_token", response.data.access_token)
      }
      return response;
    } catch (error: any) {
      throw error
    }
  });
  
  const appleSignUp = createAsyncThunk("appleSignUp", async (tokenId: any) => {
    try {
      const response = await onboardingServices.appleSignupAPI(tokenId)
      if (response.data) {
        localStorage.setItem("access_token", response.data.access_token)
      }
      return response;
    } catch (error: any) {
      throw error
    }
  });
  
  const emailSignup = createAsyncThunk("emailSignup", async (email, password) => {
    const response = await onboardingServices.emailSignupAPI(email, password);
    return response.data;
  });
  
  const setAccessToken = createAsyncThunk("accessToken", async (token: string) => {
    const response = await onboardingServices.setAccessTokenAPI(token);
    return response;
  });
  
  const getUserProfile = createAsyncThunk("getUserProfile", async (_, {getState}) => {
    const state: any = getState();
    const response = await onboardingServices.getUserProfileAPI(state["onboarding"].access_token);
    return response;
  });
  
  const changeTheme = createAsyncThunk("changeTheme", async (themeId: number, {getState}) => {
    const state: any = getState();
    const response = await onboardingServices.changeThemeAPI(state["onboarding"].access_token, themeId);
    return response.data;
  });
  
  const getConnectedSocials = createAsyncThunk("getConnectedSocials", async (_, {getState}) => {
    const state: any = getState();
    const response = await onboardingServices.getConnectedSocialsAPI(state["onboarding"].access_token)
    return response;
  });

const onboardingActions = {
  changeTheme,
  getUserInfoById,
  getConnectedSocials,
  getUserProfile,
  setAccessToken,
  emailSignin,
  emailSignup,
  googleSignUp,
  facebookSignUp,
  appleSignUp,
};
export default onboardingActions;