import * as React from "react"

const MarketIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 14" fillOpacity={0} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.4 13H11.1667V10C11.1667 9.41781 10.6731 9 10.1333 9H5.86667C5.32686 9 4.83333 9.41781 4.83333 10V13H1.6C1.58273 13 1.57194 12.9944 1.56667 12.9903V6.5V6H1.06667H0.533333C0.516063 6 0.505275 5.99443 0.5 5.99033V3.58624L1.4154 1.01168C1.41605 1.01102 1.41723 1.00998 1.41917 1.00867C1.42541 1.00445 1.4363 1 1.45107 1H14.5489C14.5637 1 14.5746 1.00445 14.5808 1.00867C14.5828 1.00998 14.584 1.01102 14.5846 1.01168L15.5 3.58624V5.99033C15.4947 5.99443 15.4839 6 15.4667 6H14.9333H14.4333V6.5V12.9903C14.4281 12.9944 14.4173 13 14.4 13Z"
      stroke={props.color}
    />
  </svg>
)

export default MarketIcon
