import * as React from "react"

const GirdMenuIcon = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill={props.color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 0.5H9.5V3.5H6.5V0.5ZM6.5 6.5H9.5V9.5H6.5V6.5ZM6.5 12.5H9.5V15.5H6.5V12.5ZM12.5 0.5H15.5V3.5H12.5V0.5ZM12.5 6.5H15.5V9.5H12.5V6.5ZM12.5 12.5H15.5V15.5H12.5V12.5ZM0.5 0.5H3.5V3.5H0.5V0.5ZM0.5 6.5H3.5V9.5H0.5V6.5ZM0.5 12.5H3.5V15.5H0.5V12.5Z"
      stroke="white"
      strokeOpacity={0.5}
    />
  </svg>
)

export default GirdMenuIcon
